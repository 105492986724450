import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
// import DatePicker, { registerLocale } from "react-datepicker";
// import es from "date-fns/locale/es";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { authFetch } from "../../utils/apiRequest";
import { CODIGO_TARIFA } from "../../utils/constants";
import {
  deleteLineaOrdenCompra,
  setLineaOrdenCompra,
  setOCBodega,
  setOCProveedor,
  setOCRefProveedor,
  updateLineaOrdenCompra,
} from "../../slices/inventorySlice";
import Spinner from "../../components/Spinner";
import NumberSteper from "../../components/NumberSteper";
import Select from "../../components/Select";
import "react-datepicker/dist/react-datepicker.css";
import { TableOrders } from "../../components/Table";
import { SearchProductOrden } from "../../components/Select/SearchProductOrden";
// registerLocale("es", es);

const OrdenesCompraAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lineasOrdeCompra, OCbodega, OCProveedor, OCRefProveedor } =
    useSelector((state) => state.inventory);
  const [catalogs, setCatalogs] = useState({
    providers: [],
    bodegas: [],
    products: [],
  });
  const [loading, setLoading] = useState(false);
  // const [fecha_entrega_estimada, setFecha_entrega_estimada] = useState();
  const [formValues, setFormValues] = useState({
    proveedorId: OCProveedor.proveedorId,
    bodegaId: OCbodega.bodegaId,
    refProvedor: OCRefProveedor.refProvedor,
  });
  const [product, setProduct] = useState({
    idProducto: null,
    nombre: "",
    fotoPrincipal: "",
  });
  const [precioUnitario, setPrecioUnitario] = useState();
  const [descuento, setDescuento] = useState(0);
  const [cantidad, setCantidad] = useState(0);
  const [impuesto, setImpuesto] = useState(8);
  const [errors, setErrors] = useState({
    errorLinea: false,
    errorGeneral: false,
  });
  const [msgError, setMsgError] = useState({
    errorLinea: "",
    errorGeneral: "",
  });
  const [indexEdit, setIndexEdit] = useState(null);
  const [lineas, setLineas] = useState(lineasOrdeCompra);
  const [indexLabel, setIndexLabel] = useState(null);

  const { token } = useSelector((store) => store.app.user);

  useEffect(() => {
    const fetchCatalogs = async () => {
      setLoading(true);
      const providersFetch = await authFetch(
        `proveedores?filter={ "where": {"activo": 1}, "order": ["nombre asc"]}`
      );
      const providers = await providersFetch.json();
      const bodegasFetch = await authFetch(
        `bodegas?filter={"where": {"activo": 1}, "order": ["nombre asc"]}`
      );
      const bodegas = await bodegasFetch.json();
      let products = [];
      if (formValues.proveedorId !== "") {
        const productsFetch = await authFetch(
          `productos?filter={"where":{"idProveedor": ${formValues.proveedorId}, "activo": 1 }, "include":[{"relation": "marca"}, {"relation": "lineasOrdenCompras","scope":{"limit":1,"order":["idOrdenCompra DESC"]}}]}`
        );
        products = await productsFetch.json();
      }
      setCatalogs({
        providers,
        bodegas,
        products,
      });
      setLoading(false);
    };
    fetchCatalogs();
    window.scrollTo(0, 0);
  }, [formValues.proveedorId]);

  const setProveedor = (value) => {
    setFormValues({ ...formValues, proveedorId: value });
    dispatch(setOCProveedor({ proveedorId: value }));
  };
  const setBodega = (value) => {
    setFormValues({ ...formValues, bodegaId: value });
    dispatch(setOCBodega({ bodegaId: value }));
  };

  const setRefProveedor = (e) => {
    setFormValues({
      ...formValues,
      refProvedor: e.target.value,
    });
    dispatch(setOCRefProveedor({ refProvedor: e.target.value }));
  };
  const changeQuantity = (newValue) => {
    setCantidad((prev) => Math.max(prev + newValue, 0));
  };

  const longitudNumero = (num) => {
    let len = Math.ceil(Math.log(num + 1) / Math.LN10);
    if (len < 11) {
      return false;
    } else {
      return true;
    }
  };

  const addLinea = () => {
    if (product.idProducto === null) {
      setErrors({ ...errors, errorLinea: true });
      setMsgError({ ...msgError, errorLinea: "Seleccione un producto" });
      return;
    }
    if (cantidad === 0 || cantidad === null) {
      setErrors({ ...errors, errorLinea: true });
      setMsgError({ ...msgError, errorLinea: "Ingrese una cantidad" });
      return;
    }
    if (precioUnitario <= 0 || precioUnitario === null) {
      setErrors({ ...errors, errorLinea: true });
      setMsgError({ ...msgError, errorLinea: "Ingrese un precio unitario" });
      return;
    }
    if (descuento < 0 || precioUnitario === null) {
      setErrors({ ...errors, errorLinea: true });
      setMsgError({ ...msgError, errorLinea: "Ingrese un descuento valido" });
      return;
    }
    if (impuesto <= 0 || impuesto === null) {
      setErrors({ ...errors, errorLinea: true });
      setMsgError({ ...msgError, errorLinea: "Ingrese un impuesto" });
      return;
    }

    if (longitudNumero(precioUnitario)) {
      setErrors({ ...errors, errorLinea: true });
      setMsgError({
        ...msgError,
        errorLinea: "Ingrese un precio unitario inferior a las 10 cifras",
      });
      return;
    }
    if (indexEdit === null) {
      const impuestoDesc = CODIGO_TARIFA.find(
        (item) => item.valor === impuesto
      );
      const linea = {
        id: uuidv4(),
        producto: {
          nombre: product.nombre,
          fotoPrincipal: product.fotoPrincipal,
        },
        idProducto: product.idProducto,
        impuestoDesc: impuestoDesc.descripción,
        cantidad,
        precioUnitario,
        descuento,
        impuesto,
      };

      if (existProduct(linea)) {
        setLineas((prev) => [...prev, linea]);
        dispatch(setLineaOrdenCompra(linea));
      } else {
        setErrors({ ...errors, errorLinea: true });
        setMsgError({
          ...errors,
          errorLinea:
            "Este producto ya existe dentro de la orden de compra, porfavor edite la linea en donde se encuntra dicho producto",
        });
        return;
      }
    }
    if (indexEdit) {
      const impuestoDesc = CODIGO_TARIFA.find(
        (item) => item.valor === impuesto
      );
      const linea = {
        id: indexEdit,
        producto: {
          nombre: product.nombre,
          fotoPrincipal: product.fotoPrincipal,
        },
        idProducto: product.idProducto,
        cantidad,
        precioUnitario,
        impuestoDesc: impuestoDesc.descripción,
        descuento,
        impuesto,
      };
      const newLineas = lineas;
      const elememtIndex = newLineas.findIndex((item) => item.id === indexEdit);
      newLineas[elememtIndex] = linea;
      setLineas(newLineas);
      dispatch(updateLineaOrdenCompra(linea));
    }

    // reset values
    setProduct({
      idProducto: null,
      nombre: "",
      fotoPrincipal: "",
    });
    setPrecioUnitario(null);
    setCantidad(0);
    setImpuesto(8);
    setIndexEdit(null);
    setDescuento(null);
    setErrors({ errorGeneral: false, errorLinea: false });
  };

  const salirOrden = async () => {
    Swal.fire({
      title: "¿Estas seguro que deseas salir?",
      text: "Si abandonas el módulo agregar una orden de compra el avance de lo realizado se perdera",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, deseo salir",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#19D3C5",
      cancelButtonColor: "#E7004C",
      background: "#fafafa",
      color: "#76236C",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/ordenes");
      }
    });
  };

  const removeLinea = (index, l) => {
    setLineas((prev) => {
      const newLineas = [...prev];
      newLineas.splice(index, 1);
      return newLineas;
    });
    if (lineas.length !== 0) {
      dispatch(deleteLineaOrdenCompra(l));
    }
  };
  const editLinea = (linea, index) => {
    setErrors({ ...errors, errorLinea: false });
    setIndexEdit(linea.id);
    setProduct({
      idProducto: linea.idProducto,
      nombre: linea.producto.nombre,
      fotoPrincipal: linea.producto.fotoPrincipal,
    });
    setPrecioUnitario(linea.precioUnitario);
    setCantidad(linea.cantidad);
    setImpuesto(linea.impuesto);
    setDescuento(linea.descuento);
    setIndexLabel(index + 1);
  };

  const existProduct = (linea) => {
    for (let i = 0; i < lineas.length; i++) {
      if (lineas[i].idProducto === linea.idProducto) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({ ...errors, errorGeneral: false });
    if (lineas && lineas.length === 0) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Agregue al menos una linea de compra",
      });
      return;
    }

    if (formValues.bodegaId === "" || formValues.bodegaId === null) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({ ...msgError, errorGeneral: "Seleccione una bodega" });
      return;
    }
    if (formValues.proveedorId === "" || formValues.proveedorId === null) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Ingrese una referencia del proveedor",
      });
      return;
    }
    setErrors({ ...errors, errorGeneral: false });

    const lineasToSend = lineas.map((item, index) => {
      return {
        linea: index + 1,
        idProducto: item.idProducto,
        cantidad: item.cantidad,
        montoDescuento: Number(item.descuento),
        precioUnitario: Number(item.precioUnitario),
        porcentajeImpuesto: item.impuesto,
      };
    });

    const OrdenesCompra = await authFetch(
      `orden-compras`,
      {
        ordenCompra: {
          idProveedor: formValues.proveedorId,
          idBodegaDestino: formValues.bodegaId,
          referenciaProveedor: formValues.refProvedor,
        },
        lineas: lineasToSend,
      },
      "POST",
      token
    );
    if (OrdenesCompra.status === 200 || OrdenesCompra.status === 204) {
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Ordenes compra creada correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setTimeout(() => {
        navigate("/ordenes");
      }, 400);
    } else {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Error al crear la orden de compra",
      });
      return;
    }
    if (OrdenesCompra.status === 400) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Ocurrió un error al crear la orden de compra",
      });
      return;
    }
    if (
      OrdenesCompra.status === 500 ||
      OrdenesCompra.status === 404 ||
      OrdenesCompra.status === 422
    ) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Ocurrió un error al crear la orden de compra",
      });
      return;
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="w-full p-content pb-16">
        {loading ? (
          <div className="min-h-[400px]">
            <Spinner />
          </div>
        ) : (
          <div>
            <div className="flex w-full items-center">
              <div className="w-full flex justify-between">
                <button
                  className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
                  onClick={salirOrden}
                >
                  <IoMdArrowRoundBack className="text-lg" /> Volver
                </button>
              </div>
            </div>

            <h2 className="my-3 font-bold text-baseGray text-2xl">
              Agrega una nueva orden de compra
            </h2>

            <form>
              <br />
              <div>
                <Select
                  containerClasses="my-4"
                  labelColor="text-base"
                  buttonClasses="border border-purple"
                  label="Proveedor:"
                  required
                  options={catalogs.providers.map((b) => {
                    return {
                      value: b.id,
                      label: b.nombre,
                    };
                  })}
                  onChange={(value) => setProveedor(value)}
                  value={formValues.proveedorId}
                />
              </div>
              <div>
                <Select
                  containerClasses="my-4"
                  labelColor="text-base"
                  buttonClasses="border border-purple"
                  label="Bodega de destino:"
                  required
                  options={catalogs.bodegas.map((b) => {
                    return {
                      value: b.id,
                      label: b.nombre,
                    };
                  })}
                  onChange={(value) => setBodega(value)}
                  value={formValues.bodegaId}
                />
              </div>

              <div className="my-2">
                <label className="">Referencia del proveedor</label>
                <input
                  onChange={(e) => setRefProveedor(e)}
                  className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
                  type={"text"}
                  name="refProvedor"
                  value={formValues.refProvedor}
                  placeholder="Referencia del proveedor"
                  required={true}
                />
              </div>
              {/*<div className="my-4">
						<label className="">Fecha entrega estimada</label>
						<div
							// onChange={(evt) => {}}
							className="w-full rounded-3xl my-2 h-12 px-4 py-3 ring-1 ring-purple outline-none"
							// type={"number"}
							// placeholder="total_exonerado"
							required={true}
						>
							<DatePicker
								className="-top-3 w-full focus:outline-none"
								onChange={(date) => setFecha_entrega_estimada(date)}
								selected={fecha_entrega_estimada}
								locale={es}
								dateFormat="MMMM dd, yyyy"
								yearDropdownItemNumber={72}
								showYearDropdown
								scrollableYearDropdown
								previousYearButtonLabel={"More"}
								nextYearButtonLabel={"More"}
							/>
						</div>
						</div>*/}
              {lineas.length > 0 && (
                <TableOrders
                  lineas={lineas}
                  removeLinea={removeLinea}
                  editLinea={editLinea}
                />
              )}
              <div>
                <hr className="my-4 bg-purple text-purple-border" />
                <label>
                  {" "}
                  Línea #{indexEdit ? indexLabel : lineas.length + 1}{" "}
                </label>
                {formValues.proveedorId && (
                  <div className="my-2">
                    <label className="">Producto</label>
                    <SearchProductOrden
                      products={catalogs.products}
                      setProduct={setProduct}
                      product={product}
                      conPrecio={true}
                      setPrecioUnitario={setPrecioUnitario}
                    />
                  </div>
                )}
                <div className="my-2">
                  <label className="ml-5">Cantidad</label>
                  <div className="my-2">
                    <NumberSteper
                      label=""
                      value={cantidad}
                      onChangeValue={changeQuantity}
                      negativeStepSize={-1}
                      positiveStepSize={1}
                    />
                  </div>
                </div>
                <div className="my-2">
                  <label className="">Precio unitario por producto</label>
                  <input
                    className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
                    type={"number"}
                    name="precioUnitario"
                    onChange={(e) => setPrecioUnitario(e.target.value)}
                    placeholder="Precio unitario por producto"
                    value={Number(precioUnitario)}
                    required={true}
                  />
                </div>
                <div className="my-2">
                  <label className="">Descuento por producto</label>
                  <input
                    className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
                    type={"number"}
                    name="precioUnitario"
                    onChange={(e) => setDescuento(e.target.value)}
                    placeholder="Descuento por producto"
                    value={Number(descuento)}
                    required={true}
                  />
                </div>
                <div className="my-2">
                  <Select
                    containerClasses="my-2"
                    labelColor="text-base"
                    buttonClasses="border border-purple"
                    label="Tarifa del impuesto:"
                    required
                    options={CODIGO_TARIFA.map((b) => {
                      return {
                        value: b.valor,
                        label: b.descripción,
                      };
                    })}
                    onChange={(value) => {
                      setImpuesto(value);
                    }}
                    value={Number(impuesto)}
                  />
                </div>
              </div>
              <div
                className="bg-purple cursor-pointer text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
                onClick={addLinea}
              >
                {indexEdit !== null
                  ? "Editar linea de compra"
                  : "Agregar linea de compra"}
              </div>
              <br />
              <br />
              {errors.errorLinea && (
                <p className="text-pink text-center">{msgError.errorLinea}</p>
              )}
              <hr className="my-4 bg-purple text-purple-border" />
              {errors.errorGeneral && (
                <p className="text-pink text-center">{msgError.errorGeneral}</p>
              )}
              <input
                className="bg-cyan cursor-pointer text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center transition-shadow duration-300 ease-linear hover:shadow-md hover:shadow-cyan/50"
                onClick={handleSubmit}
                type={"submit"}
                value={"Guardar"}
                disabled={loading}
              />
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrdenesCompraAdd;
