import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

import NumberSteper from "../../components/NumberSteper";
import Select from "../../components/Select";
import Spinner from "../../components/Spinner";
import { authFetch, singleFetch } from "../../utils/apiRequest";
import { CODIGO_TARIFA } from "../../utils/constants";
import Swal from "sweetalert2";
import { SearchProductOrden } from "../../components/Select/SearchProductOrden";
import { useSelector } from "react-redux";

export const NuevaLinea = () => {
  const navigate = useNavigate();
  const { idOrden } = useParams();
  const [catalogs, setCatalogs] = useState({
    products: [],
  });
  const [orden, setOrden] = useState(null);
  const [lineas, setLineas] = useState([]);
  const [errors, setErrors] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({
    idProducto: null,
    nombre: "",
    fotoPrincipal: "",
  });
  const [descuento, setDescuento] = useState(0);
  const [precioUnitario, setPrecioUnitario] = useState(0);
  const [cantidad, setCantidad] = useState(0);
  const [impuesto, setImpuesto] = useState(8);
  const { token } = useSelector((store) => store.app.user);

  useEffect(() => {
    const fetchOrden = async () => {
      const data = await singleFetch(
        `orden-compras/${idOrden}?filter={"include": [{"relation": "lineaOrdenCompras"}]}`
      );
      const dataJSON = await data.json();
      setOrden(dataJSON);
      if (dataJSON.lineaOrdenCompras) {
        setLineas(dataJSON.lineaOrdenCompras);
      }
    };
    if (!isNaN(idOrden)) {
      fetchOrden();
    }
  }, [idOrden]);

  const fetchCatalogs = useCallback(async () => {
    setLoading(true);
    let products = [];
    if (orden && orden.idProveedor) {
      const productsFetch = await singleFetch(
        `productos?filter={"where":{"idProveedor": ${orden.idProveedor}}, "include":[{"relation": "marca"}, {"relation": "lineasOrdenCompras","scope":{"limit":1,"order":["idOrdenCompra DESC"]}}]}`
      );
      products = await productsFetch.json();
    }
    setCatalogs({
      products,
    });
    setLoading(false);
  }, [orden]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCatalogs();
  }, [fetchCatalogs]);
  const changeQuantity = (newValue) => {
    setCantidad((prev) => Math.max(prev + newValue, 0));
  };
  const longitudNumero = (num) => {
    let len = Math.ceil(Math.log(num + 1) / Math.LN10);
    if (len < 11) {
      return false;
    } else {
      return true;
    }
  };

  const addLinea = () => {
    if (product.idProducto === null) {
      setErrors(true);
      setMsgError("Seleccione un producto");
      return;
    }
    if (cantidad === 0 || cantidad === null) {
      setErrors(true);
      setMsgError("Ingrese una cantidad");
      return;
    }
    if (precioUnitario <= 0 || precioUnitario === null) {
      setErrors(true);
      setMsgError("Ingrese un precio unitario");
      return;
    }
    if (descuento < 0 || precioUnitario === null) {
      setErrors(true);
      setMsgError("Ingrese un descuento valido");
      return;
    }
    if (impuesto === 0 || impuesto === null || impuesto === 1.13) {
      setErrors(true);
      setMsgError("Ingrese un impuesto");
      return;
    }

    if (longitudNumero(precioUnitario)) {
      setErrors(true);
      setMsgError("Ingrese un precio unitario inferior a las 10 cifras");
      return;
    }
    const linea = {
      idOrdenCompra: Number(idOrden),
      linea: lineas.length ? lineas.length + 1 : 1,
      idProducto: product.idProducto,
      cantidad: cantidad,
      precioUnitario: Number(precioUnitario),
      montoDescuento: Number(descuento),
      porcentajeImpuesto: impuesto,
    };

    const lineastoSned = lineas;
    if (existProduct(linea)) {
      lineastoSned.map((p, index) => {
        p.linea = index + 1;

        if (p.montoDescuento === null) {
          p.montoDescuento = 0;
          return p;
        } else {
          return p;
        }
      });
      lineastoSned.push(linea);
      handleSubmit(lineastoSned);
      setErrors(false);
    } else {
      setErrors(true);
      setMsgError(
        "Este producto ya existe dentro de la orden de compra, porfavor edite la linea en donde se encuntra dicho producto"
      );
      return;
    }
  };

  const existProduct = (linea) => {
    for (let i = 0; i < lineas.length; i++) {
      if (lineas[i].idProducto === linea.idProducto) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (lineastoSned) => {
    setLoading(true);

    const OrdenesCompra = await authFetch(
      `orden-compras/${idOrden}`,
      {
        ordenCompra: {
          idProveedor: orden.idProveedor,
          idBodegaDestino: orden.idBodegaDestino,
          referenciaProveedor: orden.referenciaProveedor,
          fechaEntregaEstimada: orden.idProveedor,
          fechaMaximaPago: orden.fechaMaximaPago,
        },
        lineas: lineastoSned,
      },
      "PATCH",
      token
    );

    if (
      OrdenesCompra.status === 200 ||
      OrdenesCompra.status === 204 ||
      OrdenesCompra.status === 201 ||
      OrdenesCompra.status === 202
    ) {
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Ordenes compra actualizada correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setTimeout(() => {
        navigate(`/ordenes/marcar_recibida/${idOrden}`);
      }, 400);
    } else {
      setErrors(true);
      setMsgError("Error al actualizar la orden de compra");
      return;
    }
    if (OrdenesCompra.status === 400) {
      setLoading(false);
      const dat = await OrdenesCompra.json();
      setErrors(true);
      setMsgError(dat.message);
      return;
    }
    if (OrdenesCompra.status === 500 || OrdenesCompra.status === 404) {
      setLoading(false);
      setErrors(true);
      setMsgError("Error al actualizar la orden de compra");
      return;
    }
  };

  return (
    <div className="w-full p-content pb-16">
      {loading ? (
        <div className="min-h-[400px]">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="flex w-full items-center">
            <div className="w-full flex justify-between">
              <div>
                <p className="text-lg font-medium">
                  Número de orden: #{idOrden}
                </p>
                <br />
              </div>

              <Link to={`/ordenes/marcar_recibida/${idOrden}`}>
                <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                  <IoMdArrowRoundBack className="text-lg" /> Volver
                </button>
              </Link>
            </div>
          </div>
          <div>
            <div className="my-2">
              <label className="">Producto</label>
              <SearchProductOrden
                products={catalogs.products}
                setProduct={setProduct}
                product={product}
                conPrecio={true}
                setPrecioUnitario={setPrecioUnitario}
              />
            </div>
            <div className="my-2">
              <label className="ml-5">Cantidad</label>
              <div className="my-2">
                <NumberSteper
                  label=""
                  value={cantidad}
                  onChangeValue={changeQuantity}
                  negativeStepSize={-1}
                  positiveStepSize={1}
                />
              </div>
            </div>
            <div className="my-2">
              <label className="">Precio unitario por producto</label>
              <input
                className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
                type={"number"}
                name="precioUnitario"
                onChange={(e) => setPrecioUnitario(e.target.value)}
                placeholder="Precio unitario por producto"
                value={Number(precioUnitario)}
                required={true}
              />
            </div>
            <div className="my-2">
              <label className="">Descuento por producto</label>
              <input
                className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
                type={"number"}
                name="precioUnitario"
                onChange={(e) => setDescuento(e.target.value)}
                placeholder="Descuento por producto"
                value={Number(descuento)}
                required={true}
              />
            </div>
            <div className="my-2">
              <Select
                containerClasses="my-2"
                labelColor="text-base"
                buttonClasses="border border-purple"
                label="Tarifa del impuesto:"
                required
                options={CODIGO_TARIFA.map((b) => {
                  return {
                    value: b.valor,
                    label: b.descripción,
                  };
                })}
                onChange={(value) => {
                  setImpuesto(value);
                }}
                value={Number(impuesto)}
              />
            </div>
          </div>
          <br />
          {errors && (
            <p className="text-pink text-center font-semibold">{msgError} *</p>
          )}
          <br />

          <div
            className="bg-purple select-none cursor-pointer text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
            onClick={addLinea}
          >
            Agregar linea de compra
          </div>
          <br />
        </div>
      )}
    </div>
  );
};
