import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

import CategoryBreadcrumbs from "../../components/CategoryBreadcrumbs";
import NotFoundForm from "../../components/NotFoundForm";
import ProductList from "../../components/ProductList";
import Spinner from "../../components/Spinner";
import { singleFetch } from "../../utils/apiRequest";
import FamilyList from "../../components/ProductList/FamilyList";

export function MarcaPage() {
  const navigate = useNavigate();
  const { idCategoria, idMarca } = useParams();

  const [categoryData, setCategoryData] = useState(null);

  const notFoundFormRef = useRef(null);

  const goBack = () => {
    navigate(`/tienda/categoria/${categoryData.id}`);
  };

  useEffect(() => {
    const fetchProducts = async (idCategoria, idMarca) => {
      let request;

      const filterWithoutMarca = {
        include: [
          {
            relation: "categorias",
            scope: {
              fields: { id: true, idCategoria: true, idCategoriaPadre: true },
              include: [
                {
                  relation: "categoria",
                  scope: {
                    fields: {
                      id: true,
                      nombre: true,
                      colorFondo: true,
                      imagen: true,
                    },
                  },
                },
              ],
            },
          },
          {
            relation: "productos",
            offset: 0,
            limit: 10,
            skip: 0,
            scope: {
              fields: { createdAt: false, updatedAt: false },
              where: { activo: 1 },
              order: ["marca asc", "nombreTienda asc", "precioVenta asc"],
            },
          },
          {
            relation: "familias",
            offset: 0,
            limit: 10,
            skip: 0,
            scope: {
              fields: { createdAt: false, updatedAt: false },
              where: { activo: 1 },
              order: ["marca asc", "nombreTienda asc", "precioVenta asc"],
            },
          },
          {
            relation: "categoria",
            scope: {
              fields: {
                id: true,
                nombre: true,
                colorFondo: true,
                imagen: true,
              },
            },
          },
          {
            relation: "categoriaPadre",
            scope: {
              fields: { createdAt: false, updatedAt: false },
              include: [
                {
                  relation: "categoriaPadre",
                  scope: {
                    fields: { createdAt: false, updatedAt: false },
                    include: [
                      {
                        relation: "categoriaPadre",
                        scope: {
                          fields: { createdAt: false, updatedAt: false },
                          include: [
                            {
                              relation: "categoria",
                              scope: { fields: { id: true, nombre: true } },
                            },
                          ],
                        },
                      },
                      {
                        relation: "categoria",
                        scope: { fields: { id: true, nombre: true } },
                      },
                    ],
                  },
                },
                {
                  relation: "categoria",
                  scope: { fields: { id: true, nombre: true } },
                },
              ],
            },
          },
        ],
        fields: { createdAt: false, updatedAt: false },
      };

      const filterObj = {
        include: [
          {
            relation: "categorias",
            scope: {
              fields: {
                id: true,
                idCategoria: true,
                idCategoriaPadre: true,
              },
              include: [
                {
                  relation: "categoria",
                  scope: {
                    fields: {
                      id: true,
                      nombre: true,
                      colorFondo: true,
                      imagen: true,
                    },
                  },
                },
              ],
            },
          },
          {
            relation: "productos",
            scope: {
              fields: {
                createdAt: false,
                updatedAt: false,
              },
              where: {
                idMarca: idMarca,
                activo: 1,
              },
              order: ["nombreTienda asc", "precioVenta asc"],
              include: [
                {
                  relation: "descuentos",
                },
              ],
            },
          },
          {
            relation: "familias",
            scope: {
              fields: {
                createdAt: false,
                updatedAt: false,
              },
              where: {
                idMarca,
                activo: 1,
              },
              order: ["nombreTienda asc", "precioVenta asc"],
            },
          },
          {
            relation: "marcas",
            scope: {
              limit: 1,
              fields: {
                createdAt: false,
                updatedAt: false,
              },
              where: {
                id: idMarca,
              },
            },
          },
          {
            relation: "categoria",
            scope: {
              fields: {
                id: true,
                nombre: true,
                colorFondo: true,
                imagen: true,
              },
            },
          },
          {
            relation: "categoriaPadre",
            scope: {
              fields: {
                createdAt: false,
                updatedAt: false,
              },
              include: [
                {
                  relation: "categoriaPadre",
                  scope: {
                    fields: {
                      createdAt: false,
                      updatedAt: false,
                    },
                    include: [
                      {
                        relation: "categoriaPadre",
                        scope: {
                          fields: {
                            createdAt: false,
                            updatedAt: false,
                          },
                          include: [
                            {
                              relation: "categoria",
                              scope: {
                                fields: {
                                  id: true,
                                  nombre: true,
                                },
                              },
                            },
                          ],
                        },
                      },
                      {
                        relation: "categoria",
                        scope: {
                          fields: {
                            id: true,
                            nombre: true,
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  relation: "categoria",
                  scope: {
                    fields: {
                      id: true,
                      nombre: true,
                    },
                  },
                },
              ],
            },
          },
        ],
        fields: {
          createdAt: false,
          updatedAt: false,
        },
      };

      if (idMarca === "todas") {
        request = await singleFetch(
          `categoria-categoria-padre/${idCategoria}/?filter=${JSON.stringify(
            filterWithoutMarca
          )}`
        );
      } else {
        request = await singleFetch(
          `categoria-categoria-padre/${idCategoria}/?filter=${JSON.stringify(
            filterObj
          )}`
        );
      }

      const products = await request.json();

      setCategoryData(products);
      window.scrollTo(0, 0);
    };

    fetchProducts(idCategoria, idMarca);
  }, [idCategoria, idMarca]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {!categoryData ? (
        <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white">
          <Spinner />
        </div>
      ) : (
        <div className="w-full p-content pt-4 pb-16">
          <div className="flex w-full items-center">
            <div className="w-full">
              <button
                onClick={goBack}
                className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
              >
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </div>
          </div>
          <div
            className={`border border-listingProductGray flex items-center w-full p-4 rounded-3xl my-4`}
          >
            <div className={idMarca === "todas" ? "w-full" : "w-11/12"}>
              <CategoryBreadcrumbs
                color="text-listingProductGray"
                category={categoryData}
                brand={
                  idMarca === "todas"
                    ? { nombreTienda: "Todas las marcas" }
                    : categoryData.marcas[0]
                }
              />
            </div>
            <div className={idMarca === "todas" ? "hidden" : "w-1/12"}>
              {idMarca !== "todas" && categoryData?.marcas[0]?.logo && (
                <img
                  className={`max-h-14 float-right brightness-[${Number.MAX_VALUE}]`}
                  src={categoryData.marcas[0].logo}
                  alt="Perro TiColitas"
                />
              )}
            </div>
          </div>
          {categoryData?.familias && (
            <FamilyList
              notFoundFormRef={notFoundFormRef}
              products={categoryData.familias}
              categoryId={idCategoria}
              marcaId={idMarca}
            />
          )}
          {categoryData.productos && categoryData.productos.length > 0 && (
            <ProductList
              notFoundFormRef={notFoundFormRef}
              products={categoryData.productos}
              categoryId={idCategoria}
              marcaId={idMarca}
            />
          )}
        </div>
      )}
      <NotFoundForm ref={notFoundFormRef} />
    </div>
  );
}

export default MarcaPage;
