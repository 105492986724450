import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { isNaN } from "lodash";

import { singleFetch } from "../../utils/apiRequest";
import Spinner from "../../components/Spinner";
import { TableOrders } from "../../components/Table";

const OrdenesCompraView = () => {
  const { idOrden } = useParams();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [orden, setOrden] = useState(null);

  useEffect(() => {
    const fetchOrden = async () => {
      const data = await singleFetch(
        `orden-compras/${idOrden}?filter={"include": [{"relation":"bodega"},{"relation":"proveedor"},{"relation": "lineaOrdenCompras", "scope": {"include": [{"relation": "producto"}]}}]}`
      );
      const dataJSON = await data.json();
      if (dataJSON.lineaOrdenCompras) {
        dataJSON.lineaOrdenCompras = dataJSON.lineaOrdenCompras.map((l) =>
          addImpuestoDesc(l)
        );
      }
      setOrden(dataJSON);
      setLoading(false);
    };
    if (!isNaN(idOrden)) {
      fetchOrden();
      window.scrollTo(0, 0);
    }
  }, [idOrden, dispatch]);

  const addImpuestoDesc = (l) => {
    if (l.porcentajeImpuesto === 1.13) {
      const linea = {
        cantidad: l.cantidad,
        id: l.linea,
        idProducto: l.producto.id,
        impuesto: 8,
        precioUnitario: l.precioUnitario,
        descuento: l.montoDescuento,
        producto: {
          nombre: l.producto.nombre,
          fotoPrincipal: l.producto.fotoPrincipal,
        },
        impuestoDesc: "Tarifa general 13%",
      };
      return linea;
    } else {
      const linea = {
        cantidad: l.cantidad,
        id: l.linea,
        idProducto: l.producto.id,
        impuesto: l.porcentajeImpuesto,
        precioUnitario: l.precioUnitario,
        descuento: l.montoDescuento,
        producto: {
          nombre: l.producto.nombre,
          fotoPrincipal: l.producto.fotoPrincipal,
        },
        impuestoDesc: "",
      };
      return linea;
    }
  };

  return (
    <div className="w-full p-content pb-16">
      {loading ? (
        <div className="min-h-[400px]">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="flex w-full items-center">
            <div className="w-full flex justify-between">
              <Link to="/ordenes">
                <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                  <IoMdArrowRoundBack className="text-lg" /> Volver
                </button>
              </Link>
            </div>
          </div>

          <h2 className="my-3 font-bold text-baseGray text-2xl">
            Orden de Compra #{orden ? orden.id : ""}
          </h2>

          <br />
          <div>
            <p>
              <b>Proveedor: </b>
              {orden.proveedor.nombre}
            </p>
          </div>
          <div>
            <p>
              <b>Bodega: </b>
              {orden.bodega.nombre}
            </p>
          </div>
          <div>
            <p>
              <b>Referencia del proveedor: </b>
              {orden.referenciaProveedor}
            </p>
          </div>

          {orden.lineaOrdenCompras && orden.lineaOrdenCompras.length > 0 && (
            <TableOrders
              lineas={orden.lineaOrdenCompras}
              justView
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OrdenesCompraView;
