import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Spinner from "../../components/Spinner";
import { authFetch, singleFetch } from "../../utils/apiRequest";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

export const FormInventario = () => {
  const { idProductoBodega, idBodega } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [productoBodega, setProductoBodega] = useState(null);
  const [cantidad, setCantidad] = useState({
    cantidadBodega: "",
    cantidadTransito: "",
  });

  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const { token } = useSelector((store) => store.app.user);

  useEffect(() => {
    const fetchProduct = async () => {
      const data = await singleFetch(
        `bodega-productos/bodega/${idBodega}/producto/${idProductoBodega}?filter={"include": [{"relation": "producto", "scope": {"include": [{"relation": "proveedor"}, {"relation": "marca"}]}}]}`
      );
      const dataJSON = await data.json();
      setProductoBodega(dataJSON);
      setLoading(false);
    };
    if (idProductoBodega) {
      setLoading(true);
      fetchProduct();
    }
  }, [idProductoBodega, idBodega]);

  useEffect(() => {
    if (productoBodega) {
      setCantidad({
        cantidadBodega: productoBodega.cantidadEnBodega,
        cantidadTransito: productoBodega.cantidadEnTransito,
      });
    }
    window.scrollTo(0, 0);
  }, [productoBodega]);

  const handleSubmit = async () => {
    if (cantidad.cantidadBodega < 0 || cantidad.cantidadBodega === "") {
      setMsgError("Por favor ingrese una cantidad válida");
      setError(true);
      return;
    }
    if (cantidad.cantidadTransito < 0 || cantidad.cantidadTransito === "") {
      setMsgError("Por favor ingrese una cantidad válida");
      setError(true);
      return;
    }

    setError(false);
    const request = await authFetch(
      `bodega-productos/bodega/${idBodega}/producto/${idProductoBodega}`,
      {
        idBodega: Number(idBodega),
        idProducto: Number(idProductoBodega),
        cantidadEnBodega: cantidad.cantidadBodega,
        cantidadEnTransito: cantidad.cantidadTransito,
      },
      "PATCH",
      token
    );

    if (request.status === 200 || request.status === 204) {
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Producto editado correctamente",
        background: "#76236C",
        confirmButtonColor: "#19D3C5",
        color: "#fafafa",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      setError(false);
      setTimeout(() => {
        navigate(`/bodegas/inventario/${idBodega}`);
      }, 400);
    }

    if (request.status === 400) {
      setMsgError("Ocurrió un error al editar el producto");
      setError(true);
    }
    if (
      request.status === 404 ||
      request.status === 500 ||
      request.status === 422
    ) {
      setMsgError("Ocurrió un error al editar el producto");
      setError(true);
    }
  };

  const onChangeInput = (evt) => {
    if (isNaN(evt.target.value)) {
      return;
    } else {
      setCantidad({
        ...cantidad,
        cantidadBodega: Number(evt.target.value),
      });
    }
  };

  const onChangeInputTrans = (evt) => {
    if (isNaN(evt.target.value)) {
      return;
    } else {
      setCantidad({
        ...cantidad,
        cantidadTransito: Number(evt.target.value),
      });
    }
  };

  return (
    <div className="w-full p-content pb-16">
      {loading && <Spinner />}

      {productoBodega && (
        <>
          <div>
            <div className="flex w-full items-center">
              <div className="w-full flex justify-between">
                {
                  <div>
                    <p className="text-2xl font-bold mb-4">
                      {productoBodega.producto.nombre}
                    </p>
                    <img
                      src={productoBodega.producto.fotoPrincipal}
                      alt={productoBodega.producto.nombre}
                      className="w-28 h-28"
                    />
                  </div>
                }
                <Link to={`/bodegas/inventario/${idBodega}`}>
                  <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                    <IoMdArrowRoundBack className="text-lg" /> Volver
                  </button>
                </Link>
              </div>
            </div>
            <br />
          </div>

          <div>
            <div>
              <label className="ml-5">Cantidad en bodega</label>
              <input
                onChange={onChangeInput}
                className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
                name="nameBodega"
                value={cantidad.cantidadBodega}
                placeholder="Cantidad en bodega"
                required={true}
              />
            </div>

            <div>
              <label className="ml-5">Cantidad en transito</label>
              <input
                onChange={onChangeInputTrans}
                className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
                name="nameBodega"
                value={cantidad.cantidadTransito}
                placeholder="Cantidad en transito"
                required={true}
              />
            </div>

            <input
              className="bg-cyan text-white py-2 px-8 my-3 font-bold rounded-full float-right flex cursor-pointer transition-shadow duration-300 ease-in-out justify-center items-center hover:shadow-xl hover:shadow-cyan/30"
              type="submit"
              value={"Guardar"}
              onClick={handleSubmit}
            />
            {error && <p className="text-pink text-center">{msgError}</p>}
          </div>
        </>
      )}
    </div>
  );
};
