import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { setCategoriesProduct } from "../../slices/inventorySlice";
import Spinner from "../../components/Spinner";
import SelectMultiple from "../../components/Select/Select-multiple";
import {
  authFetch,
  fileUploadFetch,
  singleFetch,
} from "../../utils/apiRequest";
import { SearchProduct } from "../../components/Select/SearchProduct";
import { TableVariations } from "../../components/Table/TableVariations";

const InventoryFamilyProductForm = () => {
  const { idFamily } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((store) => store.app.user);

  // states
  const [familyProduct, setFamilyProduct] = useState(null);

  const [loading, setLoading] = useState(false);
  const [nombre, setNombre] = useState("");
  const [imageDimensions, setImageDimensions] = useState(null);
  const [product, setProduct] = useState(null);
  const [productPhoto, setProductPhoto] = useState(null);
  const [variationsIndex, setvariationsIndex] = useState([]);
  const [catalogs, setCatalogs] = useState({
    products: [],
    variations: [],
    categoryLevels: [],
  });
  const [listVariations, setListVariations] = useState([]);
  const [variationProduct, setvariationProduct] = useState(null);
  const [listVariationProducts, setlistVariationProducts] = useState([]);
  const [indexLabel, setIndexLabel] = useState(null);
  const [indexEdit, setIndexEdit] = useState(null);
  const [errors, setErrors] = useState({
    generalMsg: "",
    generalStatus: false,
    variationMsg: "",
    variationStatus: false,
  });

  let inputs = [];

  useEffect(() => {
    const fetchCatalogs = async () => {
      setLoading(true);
      const productFetch = await singleFetch(
        `productos?filter={"include": [{"relation": "marca"}]}`
      );
      const variationFetch = await singleFetch(
        `variaciones?filter={ "include": [ { "relation": "catalogo" } ] }`
      );

      const categoryLevelsFetch = await singleFetch(
        `categoria-categoria-padre?filter={
          "where": {
            "idCategoriaPadre": {
              "neq": null
            }
          },
          "order": ["id asc"],
          "include":[{"relation":"nombre"}]
        }`
      );
      const categoryLevels = await categoryLevelsFetch.json();

      const variations = await variationFetch.json();
      const products = await productFetch.json();
      setCatalogs({
        products,
        variations,
        categoryLevels,
      });
      setLoading(false);
    };
    dispatch(setCategoriesProduct([]));
    fetchCatalogs();
  }, [dispatch]);

  useEffect(() => {
    const fetchFamily = async () => {
      const data = await singleFetch(`familias/structured/${idFamily}`);
      const dataJSON = await data.json();
      setFamilyProduct(dataJSON);
      // setProduct(dataJSON);
      setLoading(false);
    };

    if (!isNaN(idFamily) && catalogs.variations.length > 0) {
      fetchFamily();
      setLoading(true);
    }
    window.scrollTo(0, 0);
  }, [idFamily, catalogs]);

  const structure = useCallback(
    (pv, p) => {
      let props = {};
      // eslint-disable-next-line
      catalogs.variations.map((cv, index) => {
        // eslint-disable-next-line
        pv.map((pvv) => {
          if (cv.id === pvv.idVariacion) {
            props = { ...props, [cv.nombre]: pvv.valor };
          }
        });
      });
      setlistVariationProducts((prev) => [
        ...prev,
        {
          id: uuidv4(),
          product: p.producto,
          ...props,
        },
      ]);
    },
    [catalogs]
  );

  useEffect(() => {
    if (familyProduct) {
      setNombre(familyProduct.familia.nombre);
      familyProduct.variaciones.map((v) =>
        setvariationsIndex((prev) => [...prev, v.id])
      );
      familyProduct.variaciones.map((v) =>
        setListVariations((prev) => [...prev, v])
      );
      familyProduct.productos.map((p) => structure(p.variaciones, p));
    }
  }, [familyProduct, structure]);

  // Nota: Esto es para el select multiple
  const splitCategories = useCallback(
    (categories) => {
      dispatch(setCategoriesProduct(categories));
    },
    [dispatch]
  );

  useEffect(() => {
    if (familyProduct) {
      let categories = [];
      familyProduct.variaciones.map((v) => categories.push(v.id));
      splitCategories(categories);
    }
  }, [familyProduct, splitCategories]);

  const handleChage = (v) => {
    setvariationsIndex(v);
    if (listVariations <= 0) {
      v.map((c) =>
        catalogs.variations.map(
          (v) => c === v.id && setListVariations([...listVariations, v])
        )
      );
    } else {
      v.map((c) =>
        catalogs.variations.map(
          (v) =>
            c === v.id &&
            !listVariations.includes(v) &&
            setListVariations([...listVariations, v])
        )
      );
    }
  };

  // si una varacion ya existe en el arreglo
  const existProduct = (variation) => {
    for (let i = 0; i < listVariationProducts.length; i++) {
      if (listVariationProducts[i].product.id === variation.product.id) {
        return false;
      }
    }
    return true;
  };

  // Nota: Esto es para saber si existe un valor en los campos de texto dinamicos
  const existValue = (variation) => {
    for (var key in variation) {
      if (variation[key] === "" || variation[key].trim() === "") {
        return true;
      }
    }
    return false;
  };

  const createVariations = (e) => {
    e.preventDefault();

    if (product === null) {
      setErrors({
        ...errors,
        variationMsg: "Debe de agregar un producto",
        variationStatus: true,
      });
      return;
    }

    if (variationProduct === null && indexEdit === null) {
      setErrors({
        ...errors,
        variationMsg: "Los campos no pueden ir vacios",
        variationStatus: true,
      });
      return;
    }

    if (
      indexEdit === null &&
      Object.keys(variationProduct).length < listVariations.length
    ) {
      setErrors({
        ...errors,
        variationMsg:
          "Existen campos vacios por favor complete todos los campos obligatorios",
        variationStatus: true,
      });
      return;
    }

    if (existValue(variationProduct) === true) {
      setErrors({
        ...errors,
        variationMsg:
          "Existen campos vacios por favor complete todos los campos obligatorios",
        variationStatus: true,
      });
      return;
    }

    if (indexEdit === null) {
      // nota si index edit no existe significa que es una nueva variacion
      // por eso se crea el objeto productVariation y se agrefa al arreglo de variaciones
      const productVariation = {
        id: uuidv4(),
        product,
        ...variationProduct,
      };
      if (existProduct(productVariation)) {
        setlistVariationProducts([...listVariationProducts, productVariation]);
      } else {
        setErrors({
          ...errors,
          variationMsg: "Este producto ya existe, dentro de las variaciones",
          variationStatus: true,
        });
        return;
      }
    }

    if (indexEdit) {
      // en caso de que existe indexEdit, se crea el un nuevo objeto productVariation
      // y este se se cambia por su anterior en el arreglo
      const productVariation = {
        id: indexEdit,
        product,
        ...variationProduct,
      };
      const newVariations = listVariationProducts;
      const elememtIndex = newVariations.findIndex(
        (item) => item.id === indexEdit
      );
      newVariations[elememtIndex] = productVariation;
      setlistVariationProducts(newVariations);
    }

    /**Limpiar forms */
    setProduct(null);
    setIndexEdit(null);
    setvariationProduct(null);
    setErrors({
      variationMsg: "",
      variationStatus: false,
      generalMsg: "",
      generalStatus: false,
    });
    e.target.reset();
  };

  const handleSendData = async () => {
    if (isNaN(idFamily)) {
      if (productPhoto === null) {
        setErrors({
          ...errors,
          generalMsg: "La imagen es obligatoria",
          generalStatus: true,
        });
        return;
      }

      if (imageDimensions.height !== imageDimensions.width) {
        setErrors({
          ...errors,
          generalMsg:
            "La imagen cargada no cumple con las dimensiones en pixeles preestablecidas.",
          generalStatus: true,
        });
        return;
      }
    }

    if (nombre === null || nombre === "" || nombre.trim() === "") {
      setErrors({
        ...errors,
        generalMsg: "El nombre es obligatorio",
        generalStatus: true,
      });
      return;
    }

    if (listVariationProducts.length === 0) {
      setErrors({
        ...errors,
        generalMsg: "Debe de agregar al menos una variación a este producto",
        generalStatus: true,
      });
      return;
    }

    let myNewFile = null;
    if (productPhoto) {
      myNewFile = new File([productPhoto], nombre ? nombre : "imgfoto", {
        type: productPhoto.type,
      });
    }

    let productos = [];
    // eslint-disable-next-line
    listVariationProducts.map((p) => {
      let variaciones = [];
      // eslint-disable-next-line
      Object.entries(p).map(([key, value]) => {
        // eslint-disable-next-line
        listVariations.map((ls) => {
          if (key === ls.nombre) {
            variaciones = [
              ...variaciones,
              {
                idVariacion: ls.id,
                valor: value,
              },
            ];
          }
        });
      });
      productos.push({
        idProducto: p.product.id,
        variaciones: variaciones,
      });
    });
    if (isNaN(idFamily)) {
      handlePost(nombre, myNewFile, variationsIndex, productos);
    } else {
      handlePatch(nombre, myNewFile, variationsIndex, productos);
    }
  };

  const handlePost = async (nombre, Photo, variationsIndex, productos) => {
    let s3PhotoAddress;
    setLoading(true);
    window.scrollTo(0, 0);

    if (Photo) {
      try {
        const s3PhotoAddressFetch = await fileUploadFetch("upload", Photo);
        if (s3PhotoAddressFetch.status === 200) {
          s3PhotoAddress = await s3PhotoAddressFetch.json();
        }
        // fotoPrincipal = s3PhotoAddress
      } catch (error) {
        console.log(error);
      }
    }

    let request = await authFetch(
      "familias",
      {
        familia: {
          nombre: nombre,
          fotoPrincipal: s3PhotoAddress.s3Address,
        },
        variaciones: variationsIndex,
        productos,
      },
      "POST",
      token
    );

    if (request.status === 200 || request.status === 204) {
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Variación de productos agregada correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setLoading(false);
      setTimeout(() => {
        navigate("/inventario/familias");
      }, 400);
    }

    if (
      request.status === 400 ||
      request.status === 404 ||
      request.status === 500
    ) {
      setLoading(false);
      setErrors({
        ...errors,
        generalMsg: "Error en agregar una variación de productos",
        generalStatus: true,
      });
      return;
    }
  };

  const handlePatch = async (nombre, Photo, variationsIndex, productos) => {
    let s3PhotoAddress;
    setLoading(true);
    window.scrollTo(0, 0);

    if (Photo) {
      if (imageDimensions.height !== imageDimensions.width) {
        setErrors({
          ...errors,
          generalMsg:
            "La imagen cargada no cumple con las dimensiones en pixeles preestablecidas.",
          generalStatus: true,
        });
        return;
      }
      try {
        const s3PhotoAddressFetch = await fileUploadFetch("upload", Photo);
        if (s3PhotoAddressFetch.status === 200) {
          s3PhotoAddress = await s3PhotoAddressFetch.json();
        }
        // fotoPrincipal = s3PhotoAddress
      } catch (error) {
        console.log(error);
      }
    } else {
      s3PhotoAddress = familyProduct.familia.fotoPrincipal;
    }

    let request = await authFetch(
      `familias/${idFamily}`,
      {
        familia: {
          nombre: nombre,
          fotoPrincipal: Photo ? s3PhotoAddress.s3Address : s3PhotoAddress,
        },
        variaciones: variationsIndex,
        productos,
      },
      "PATCH",
      token
    );

    if (request.status === 200 || request.status === 204) {
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Variación de productos actualizada correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setLoading(false);
      setTimeout(() => {
        navigate("/inventario/familias");
      }, 400);
    }

    if (
      request.status === 400 ||
      request.status === 404 ||
      request.status === 500
    ) {
      setLoading(false);
      setErrors({
        ...errors,
        generalMsg: "Error en actualizar una variación de productos",
        generalStatus: true,
      });
      return;
    }
  };

  const handleInputChange = (e) => {
    setvariationProduct({
      ...variationProduct,
      [e.target.name]: e.target.value,
    });
  };

  const closeVariations = () => {
    navigate("/inventario/familias");
  };

  // para editar una variacion se agregan sus datos al state e inputs
  const editVariation = (variation, index) => {
    // agregat datos a inputs
    listVariations.map((v) =>
      inputs.push(document.getElementById(`${v.nombre}`))
    );
    inputs.map(
      (i, index) => (i.value = variation[listVariations[index].nombre])
    );

    // agregar datos al state
    listVariations.map((l) =>
      setvariationProduct((prev) => {
        return { ...prev, [l.nombre]: variation[l.nombre] };
      })
    );
    setProduct(variation.product);
    setIndexEdit(variation.id);
    setIndexLabel(index + 1);
    // setErrors({ ...errors, errorLinea: false });
  };

  const removeVariation = (index, l) => {
    setlistVariationProducts((prev) => {
      const newVariations = [...prev];
      newVariations.splice(index, 1);
      return newVariations;
    });
  };

  const cleanVariations = () => {
    setListVariations([]);
    setvariationsIndex([]);
  };

  return (
    <div>
      <div>
        <div className="w-full p-content pb-16">
          {loading ? (
            <div className="min-h-[400px]">
              <Spinner />
            </div>
          ) : (
            <div>
              <button
                className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
                onClick={closeVariations}
              >
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
              <h2 className="my-3 font-bold text-baseGray text-2xl">
                {isNaN(idFamily) ? "Agrega" : "Editar"} una nueva familias de
                productos
              </h2>

              <div className="w-full mt-5">
                <>
                  <label className="text-base">Fotografía del producto:</label>
                  <p className="text-sm text-pink">
                    La fotografía debe ser de un tamaño de 800x800 pixeles con
                    fondo blanco en formato{" "}
                    <span className="font-bold">.jpg</span>. Este es un estandar
                    del sitio web y no se debe modificar.
                  </p>
                  <label
                    className="w-fit mt-2 block text-sm text-body py-2 px-4 rounded-full border-0 bg-pink text-white"
                    htmlFor="comprobantePago"
                  >
                    Cargar fotografía
                  </label>
                  <input
                    id="comprobantePago"
                    name="comprobantePago"
                    type="file"
                    accept="image/jpeg"
                    className="hidden"
                    onChange={(evt) => {
                      const img = new Image();
                      const objectUrl = URL.createObjectURL(
                        evt.target.files[0]
                      );
                      img.onload = function () {
                        setImageDimensions({
                          width: this.width,
                          height: this.height,
                        });
                        URL.revokeObjectURL(objectUrl);
                      };
                      img.src = objectUrl;

                      setProductPhoto(evt.target.files[0]);
                    }}
                  />
                </>
                {!isNaN(idFamily) ? (
                  <img
                    className="my-3 h-52"
                    src={
                      productPhoto
                        ? URL.createObjectURL(productPhoto)
                        : familyProduct && familyProduct.familia.fotoPrincipal
                    }
                    alt="Imagen del producto"
                  />
                ) : (
                  <img
                    className="my-3 h-52"
                    src={productPhoto && URL.createObjectURL(productPhoto)}
                    alt="Imagen del producto"
                  />
                )}
              </div>

              <div>
                <label htmlFor="nombreProveedor" className="text-base">
                  Nombre del producto en tienda:
                  <span className="text-pink">*</span>
                </label>
                <input
                  id="nombreProveedor"
                  name="nombreProveedor"
                  className="w-full rounded-3xl my-2 h-12 p-6 border border-purple outline-none"
                  onChange={(evt) => {
                    setNombre(evt.target.value);
                  }}
                  value={nombre}
                />
              </div>

              <SelectMultiple
                required
                containerClasses="my-2"
                labelColor="text-base"
                buttonClasses="border border-purple"
                label={`Variaciones de producto: `}
                options={catalogs.variations.map((b) => {
                  return {
                    value: b.id,
                    label: b.nombre,
                  };
                })}
                onChange={(value) => handleChage(value)}
                value={variationsIndex}
                spliceElement={true}
                cleanVariations={cleanVariations}
              />
              {listVariationProducts && listVariationProducts.length > 0 && (
                <TableVariations
                  variations={listVariationProducts}
                  listVariations={listVariations}
                  removeVariation={removeVariation}
                  editVariation={editVariation}
                />
              )}

              {listVariations && listVariations.length > 0 && (
                <div>
                  <hr className="my-4 bg-purple text-purple-border" />
                  <label className="font-semibold">
                    Variación #
                    {indexEdit ? indexLabel : listVariationProducts.length + 1}{" "}
                  </label>
                  <div className="my-2">
                    <label className="">Producto</label>
                    <SearchProduct
                      products={catalogs.products}
                      setProduct={setProduct}
                      product={product}
                      allProducts={true}
                      notQuantity={true}
                    />
                  </div>
                  <form onSubmit={createVariations}>
                    {listVariations.map((l) => (
                      <div key={l.id}>
                        <label htmlFor={l.nombre} className="text-base">
                          {l.nombre}
                          <span className="text-pink">*</span>
                        </label>
                        <input
                          name={l.nombre}
                          id={l.nombre}
                          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple outline-none"
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                      </div>
                    ))}

                    <input
                      type={"submit"}
                      className="bg-purple text-white py-2 px-8 font-bold rounded-full outline-none"
                      value={indexEdit ? "Editar variación" : "Crear variación"}
                    />
                    {errors.variationStatus && (
                      <p className="text-pink text-center">
                        {errors.variationMsg}
                      </p>
                    )}
                  </form>
                </div>
              )}
              {listVariationProducts && listVariationProducts.length > 0 && (
                <>
                  <hr className="my-4 bg-purple text-purple-border" />

                  {errors.generalStatus && (
                    <p className="text-pink text-center">{errors.generalMsg}</p>
                  )}

                  <button
                    className="bg-cyan text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
                    onClick={handleSendData}
                  >
                    Guardar
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InventoryFamilyProductForm;
