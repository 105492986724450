import React, { useCallback, useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { singleFetch } from "../../utils/apiRequest";

export const TiendaBodegas = () => {
	const [bodegas, setBodegas] = useState();
	const [loading, setLoading] = useState(false);


	const fetchBodegas = useCallback(async () => {
		setLoading(true);

		const bodegaFecth = await singleFetch(
			`bodegas?filter={
          "where": {"activo": 1},
          "order": ["nombre asc"]}`
		);
		const bodegasData = await bodegaFecth.json();
		setBodegas(bodegasData);
		setLoading(false);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		fetchBodegas();
	}, [fetchBodegas]);
	return (
	  <>
      <div className="w-full p-content pb-16">
        {loading && <Spinner />}
        <div>
          <div className="flex w-full items-center">
            <div className="w-full flex justify-between">
              <div>
              </div>
              <Link to="/cuenta">
                <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                  <IoMdArrowRoundBack className="text-lg" /> Volver
                </button>
              </Link>
            </div>
          </div>
          <br />

          
          {bodegas && bodegas.length > 0 ? (
            <>
              {bodegas.map(
                (bodega, index) =>
                  bodega.activo === 1 && (
                    <div
                      className="w-full ring-1 ring-purple rounded-lg mb-4 p-6"
                      key={index}
                    >
                      <div className="flex items-center grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="w-full md:w-1/2">
                          <p className="text-2xl font-semibold text-baseGray">
                            {bodega.nombre}
                          </p>
                          <p className="text-lg font-medium text-baseGray">
                            {bodega.email}
                          </p>
                        </div>
                      </div>
                      <div className="flex w-full justify-end mt-6">
                        <Link
                          to={`/bodegas/${bodega.id}/ordenes`}
                          className="rounded-3xl py-2 px-8 my-1 font-medium text-white bg-purple"
                        >
                          Ver pedidos
                        </Link>
                      </div>
                    </div>
                  )
              )}

             
            </>
          ) : (
            !loading && <p>No hay bodegas para mostrar</p>
          )}
        </div>
      </div>
    </>
	);
};
