import React, { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { authFetch, singleFetch } from "../../utils/apiRequest";
import { getCurrentDay } from "../../utils/formatDates";
import { BeatLoader, ClipLoader } from "react-spinners";
import PendientesPagoTable from "../../components/PendientesPagoTable";

export default function PendientesPage() {
  const [transportista, setTransportista] = useState();
  const [loadingTransportista, setLoadingTransportista] = useState(false);
  const { token } = useSelector((store) => store.app.user);

  const { user } = useSelector((store) => store.app);

  const [dateSelected, setDateSelected] = useState(getCurrentDay());

  const [pendientes, setPendientes] = useState();
  const [loading, setLoading] = useState(false);

  const fetchPendientes = async () => {
    setLoading(true);
    const res = await authFetch(
      `cierres/transportista/${transportista.id}/fecha/${dateSelected}`,
      null,
      "POST",
      token
    );
    const data = await res.json();
    setPendientes(data.pendientes);
    setLoading(false);
  };

  useEffect(() => {
    const fetchTransportista = async () => {
      setLoadingTransportista(true);
      const res = await singleFetch(`transportista/usuario/${user.id}`);
      const data = await res.json();
      setTransportista(data);
      setLoadingTransportista(false);
    };

    fetchTransportista();
  }, [user.id]);

  return (
    <div className="w-full p-content pb-16">
      <div className="flex flex-col gap-4">
        <Link to="/inventario">
          <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
            <IoMdArrowRoundBack className="text-lg" /> Volver
          </button>
        </Link>
        <h2 className="font-bold text-2xl">Clientes con pagos pendientes</h2>
        <div className="flex flex-col gap-2">
          <label>Fecha:</label>
          <input
            type={"date"}
            max={getCurrentDay()}
            value={dateSelected}
            onChange={(e) => setDateSelected(e.target.value)}
            required={true}
            className="border-purple outline-purple p-4 rounded-full border"
          />
        </div>

        {loadingTransportista && <BeatLoader color="#76236C" />}

        {transportista && (
          <>
            <p className="font-bold">
              Transportista:{" "}
              <span className="font-medium">{transportista.nombre}</span>{" "}
            </p>
            <div className="flex justify-end" onClick={() => fetchPendientes()}>
              <button className="bg-purple text-white px-4 py-3 rounded-full mt-2">
                Buscar Pendientes
              </button>
            </div>
          </>
        )}

        <div className="mt-10">
          {loading && (
            <div className="flex justify-center w-full">
              <ClipLoader color="#76236C" />
            </div>
          )}

          {pendientes && !loading && (
            <PendientesPagoTable pendientes={pendientes} />
          )}
        </div>
      </div>
    </div>
  );
}
