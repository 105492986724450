import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IoMdArrowRoundBack } from "react-icons/io";

import Spinner from "../../components/Spinner";
import NumberSteper from "../../components/NumberSteper";
import { singleFetch } from "../../utils/apiRequest";

import moment from "moment";
import "moment/locale/es";
import { addToCart } from "../../slices/appSlice";
moment.updateLocale("es");

export function ClientePedidosPage({ user, cart }) {
  const dispatch = useDispatch(null);

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idOfProductWithNoMoreitems, setIdOfProductWithNoMoreitems] =
    useState(null);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    const ordersFetch = await singleFetch(
      `ventas?filter={"order": "fechaIngreso desc","limit": 1000,"where":{"idUsuario": ${user.id}},"include":[{"relation":"metodoPagoUtilizado"},{"relation":"metodoEntregaUtilizado"},{"relation":"usuario"},{"relation":"lineas","scope":{"include":[{"relation":"producto"}]}},{"relation":"transportista","scope":{"include":[{"relation":"usuario"}]}}]}`
    );

    const ordersData = await ordersFetch.json();
    setOrders(ordersData);
    setLoading(false);
  }, [user.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrders();
  }, [fetchOrders]);

  const reOrder = (order) => {
    order.lineas.forEach((l) => {
      if (l.cantidad <= l.producto.cantidadEnBodega) {
        dispatch(
          addToCart({
            product: l.producto,
            quantity: l.cantidad,
            dailyConsumption: 0,
            whatsappConfirmation: false,
            estimatedOutOfFoodDate: "",
            dontOpenCart: true,
          })
        );
      }
    });
  };

  return (
    <div className="w-full p-content pb-16 min-h-[400px]">
      {loading && <Spinner />}
      <div>
        <div className="flex w-full items-center">
          <div className="w-full">
            <Link to="/cuenta">
              <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
              >
              <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </Link>
          </div>
        </div>
        <p className="text-2xl font-bold mb-4">Tus pedidos</p>

        <div className="w-full">
          {orders && orders.length > 0
            ? orders.map((v) => {
                return (
                  <div
                    className="w-full border border-purple mb-4 p-6"
                    key={`venta_${v.id}`}
                  >
                    <div>
                      <p className="text-2xl mb-4">Orden #{v.id}</p>
                      <p className="text-md mb-2">
                        Cliente:{" "}
                        <span className="font-medium">
                          {v.usuario.nombre}
                          {v.usuario.apellido1 ? ` ${v.usuario.apellido1}` : ""}
                        </span>
                      </p>
                      <p className="text-md mb-2">
                        Fecha:{" "}
                        <span className="font-medium">
                          {moment(v.fechaIngreso).format(
                            "dddd D [de] MMMM [a la(s)] hh:mm a"
                          )}
                        </span>
                      </p>
                      <p className="text-md mb-2">
                        Total:{" "}
                        <span className="font-medium">
                          {Intl.NumberFormat("es-CR", {
                            style: "currency",
                            currency: "CRC",
                          }).format(v.totalConTransporte)}
                        </span>
                      </p>
                      <p className="text-md mb-2">
                        Metodo de Entrega:{" "}
                        <span className="font-medium">
                          {v.metodoEntregaUtilizado.nombre}
                        </span>
                      </p>
                      <p className="text-md mb-2">
                        Metodo de Pago:{" "}
                        <span className="font-medium">
                          {v.metodoPagoUtilizado.nombre}
                        </span>
                      </p>
                      <p className="text-md mb-2">
                        Notas:{" "}
                        <span className="font-medium">
                          {v.notasEntrega || "No hay notas"}
                        </span>
                      </p>
                    </div>
                    {v.lineas.map((l) => {
                      return (
                        <div
                          key={`venta_${v.id}_${l.linea}`}
                          className="flex items-center my-4 border-b border-borderGray pb-4"
                        >
                          <div className="w-4/12 md:w-2/12">
                            <img
                              src={l.producto.fotoPrincipal}
                              alt={`Foto ${l.producto.nombreTienda}`}
                            />
                          </div>
                          <div className="flex flex-wrap items-center w-8/12 md:w-10/12 pl-4">
                            <p className="w-full font-bold">
                              {l.producto.marca} {l.producto.nombreTienda}
                            </p>
                            {l.producto.pesoGramos && (
                              <p className="w-full">
                                Presentación:{" "}
                                <span className="font-bold">
                                  {l.producto.pesoGramos < 1000
                                    ? `${l.producto.pesoGramos} gramos`
                                    : `${(l.producto.pesoGramos / 1000).toFixed(
                                        2
                                      )} Kg`}
                                </span>
                              </p>
                            )}
                            <p className="w-full">
                              Cantidad:{" "}
                              <span className="font-bold">{l.cantidad}</span>
                            </p>
                            <p className="w-full">
                              Precio unitario:{" "}
                              <span className="font-bold">
                                {Intl.NumberFormat("es-CR", {
                                  style: "currency",
                                  currency: "CRC",
                                }).format(l.costoUnitario)}
                              </span>
                            </p>
                            <p className="w-full">
                              Total:{" "}
                              <span className="font-bold">
                                {Intl.NumberFormat("es-CR", {
                                  style: "currency",
                                  currency: "CRC",
                                }).format(l.total)}
                              </span>
                            </p>
                            <div className="mt-4">
                              <NumberSteper
                                label="Si deseas agregar a la tacita de compra este producto indicá la cantidad:"
                                labelColor="text-base"
                                value={
                                  cart.products[parseInt(l.idProducto)]
                                    ? cart.products[parseInt(l.idProducto)]
                                        .quantity
                                    : 0
                                }
                                onChangeValue={(value) => {
                                  setIdOfProductWithNoMoreitems(null);
                                  if (
                                    value < 0 &&
                                    (!cart.products[parseInt(l.idProducto)] ||
                                      !cart.products[parseInt(l.idProducto)]
                                        .quantity ||
                                      cart.products[parseInt(l.idProducto)]
                                        .quantity === 0)
                                  ) {
                                    return;
                                  }
                                  if (
                                    value > 0 &&
                                    cart.products[parseInt(l.idProducto)] &&
                                    cart.products[parseInt(l.idProducto)]
                                      .quantity === l.producto.cantidadEnBodega
                                  ) {
                                    setIdOfProductWithNoMoreitems(l.idProducto);
                                    return;
                                  }
                                  dispatch(
                                    addToCart({
                                      product: l.producto,
                                      quantity: cart.products[
                                        parseInt(l.idProducto)
                                      ]
                                        ? cart.products[parseInt(l.idProducto)]
                                            .quantity
                                          ? cart.products[
                                              parseInt(l.idProducto)
                                            ].quantity + value
                                          : value
                                        : value,
                                      dailyConsumption: 0,
                                      whatsappConfirmation: false,
                                      estimatedOutOfFoodDate: "",
                                      dontOpenCart: true,
                                    })
                                  );
                                }}
                                negativeStepSize={-1}
                                positiveStepSize={1}
                              />
                            </div>
                            {l.idProducto === idOfProductWithNoMoreitems && (
                              <p className="text-sm text-pink">
                                Lo sentimos, pronto tendremos más unidades
                                disponibles de este artículo.
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <div className="mt-6 flex justify-end">
                      <Link
                        onClick={() => {
                          reOrder(v);
                        }}
                        to={`/checkout?reorder=true`}
                      >
                        <button className="ml-4 bg-purple py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white">
                          Volver a comprar toda la orden
                        </button>
                      </Link>
                      <Link to={`/pedidos/${v.id}?desde=/pedidos`}>
                        <button className="ml-4 bg-purple py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white">
                          Ver Detalles
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })
            : !loading && <p>No hay pedidos para mostrar</p>}
        </div>
      </div>
    </div>
  );
}

export default ClientePedidosPage;
