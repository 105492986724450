import React, { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import otraOportunidad from "../../assets/otra-oportunidad.png";
import { fileUploadFetch, authFetch } from "../../utils/apiRequest";
import Spinner from "../Spinner";

const NotFoundForm = forwardRef(
  ({ product, fromQR, requestedQuantity, nameParam, phoneParam }, ref) => {
    const [whatAreLookingFor, setWhatAreLookingFor] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [productPhoto, setProductPhoto] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState();
    const [loading, setLoading] = useState(false);
    const { token } = useSelector((store) => store.app.user);

    useEffect(() => {
      if (product) {
        setWhatAreLookingFor(
          `${
            requestedQuantity
              ? `El cliente quiere ${requestedQuantity} ${
                  parseInt(requestedQuantity) === 1 ? "unidad" : "unidades"
                } de `
              : ""
          }${product.codigoProveedor} - ${product.marca} ${
            product.nombreTienda
          } ${
            product.pesoGramos < 1000
              ? `${product.pesoGramos} gramos`
              : `${(product.pesoGramos / 1000).toFixed(2)} Kg`
          }. Por favor confirme la cantidad final con el cliente antes de proceder con la orden.`
        );
      }
    }, [product, requestedQuantity]);

    useEffect(() => {
      if (nameParam) {
        setName(nameParam);
      }
    }, [nameParam]);

    useEffect(() => {
      if (phoneParam) {
        setPhone(phoneParam);
      }
    }, [phoneParam]);

    const sendForm = async () => {
      setError(null);
      if (whatAreLookingFor && phone && name) {
        setLoading(true);
        let s3PhotoAddress;
        if (productPhoto) {
          try {
            const s3PhotoAddressFetch = await fileUploadFetch(
              "upload",
              productPhoto
            );
            if (s3PhotoAddressFetch.status === 200) {
              s3PhotoAddress = await s3PhotoAddressFetch.json();
            }
          } catch (error) {}
        }
        const request = await authFetch(
          "solicitud-productos",
          {
            queBusca: whatAreLookingFor,
            nombre: name,
            telefono: phone,
            fotoProducto: s3PhotoAddress ? s3PhotoAddress.s3Address : "",
          },
          "POST",
          token
        );
        if (request.status === 200) {
          setSuccess("Gracias. Hemos recibido tu solicitud exitosamente.");
          setWhatAreLookingFor("");
          setName("");
          setProductPhoto(null);
        } else {
          setError("Ups. Algo salió mal, por favor intenta de nuevo.");
        }
        setLoading(false);
      } else {
        setError("Por favor completa todos los campos solicitados.");
      }
    };

    return (
      <div ref={ref} className="bg-pink py-16">
        {loading && <Spinner />}
        <div
          className={`w-full p-content text-center text-white ${
            !product ? "text-3xl sm:text-4xl" : ""
          } mb-10 z-10`}
        >
          {product ? (
            <p className="font-title mt-2 text-3xl">
              Lo sentimos, intentaste agregar {fromQR ? requestedQuantity : ""}{" "}
              <span className="text-4xl">
                {product.marca} {product.nombreTienda}{" "}
                {product.pesoGramos < 1000
                  ? `${product.pesoGramos} gramos`
                  : `${(product.pesoGramos / 1000).toFixed(2)} Kg`}
              </span>{" "}
              a tu tacita de compras, y en este momento no tenemos{" "}
              {fromQR ? "la cantidad solicitada" : ""} en inventario. Si nos
              dejas tus datos, coordinaremos para tenértelo lo antes posible
              {fromQR && product && product.cantidadEnBodega > 0
                ? ", o podés intentar con otra cantidad"
                : ""}
              .
            </p>
          ) : (
            <p className="font-title uppercase mt-2">
              ¿Buscás otra marca, producto o presentación? Queremos tener el
              alimento de tu mascota disponible, por favor danos una
              oportunidad.
            </p>
          )}
        </div>
        <div className="flex items-center w-full z-0">
          <div className="w-7/12 pl-4 md:pl-20 lg:pl-40 xl:pl-60">
            {!success && (
              <>
                {!product && (
                  <div>
                    <label htmlFor="que-estas-buscando" className="text-white">
                      Por favor contanos lo que necesitas para tenértelo
                      disponible.
                    </label>
                    <textarea
                      id="que-estas-buscando"
                      name="que-estas-buscando"
                      className="w-full rounded-3xl mt-2 p-6 resize-none outline-none no-scrollbar"
                      rows={3}
                      value={whatAreLookingFor}
                      onChange={(evt) => {
                        setWhatAreLookingFor(evt.target.value);
                      }}
                      placeholder={
                        "Por favor se lo más específico posible. Indicanos el nombre, peso y otras características del producto."
                      }
                    />
                  </div>
                )}
                {fromQR && product && product.cantidadEnBodega > 0 && (
                  <div className="w-full flex flex-wrap justify-center text-center">
                    <Link
                      className="w-full"
                      to={`/tienda/producto/${product.id}`}
                    >
                      <button
                        type="button"
                        className="text-pink bg-white rounded-full w-full py-3 my-9"
                      >
                        Intentar con otra cantidad
                      </button>
                    </Link>
                    <p className="text-white w-full mb-9">- ó -</p>
                    <p className="text-white w-full mb-9">Dejános tus datos</p>
                  </div>
                )}
                <div>
                  <label htmlFor="nombre" className="text-white">
                    Tu nombre
                  </label>
                  <input
                    id="nombre"
                    name="nombre"
                    className="w-full rounded-3xl my-2 h-12 p-6 outline-none"
                    value={name}
                    onChange={(evt) => {
                      setName(evt.target.value);
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="info-contacto" className="text-white">
                    ¿Dónde te podemos contactar?
                  </label>
                  <input
                    id="info-contacto"
                    name="info-contacto"
                    className="w-full rounded-3xl mt-2 h-12 p-6 outline-none"
                    placeholder="Correo electrónico, teléfono, etc"
                    value={phone}
                    onChange={(evt) => {
                      setPhone(evt.target.value);
                    }}
                  />
                </div>
                {!product && (
                  <div className="w-full mt-4">
                    <label className="text-white" htmlFor="comprobantePago">
                      Si te es posible, podés cargar una foto del producto aquí:
                    </label>
                    <input
                      id="comprobantePago"
                      name="comprobantePago"
                      type="file"
                      className="mt-2 outline-none cursor-pointer block w-full text-sm text-body text-white
                              file:mr-4 file:py-2 file:px-4
                              file:rounded-full file:border-0
                              file:text-sm
                              file:bg-white file:text-pink
                             
                            "
                      onChange={(evt) => {
                        setProductPhoto(evt.target.files[0]);
                      }}
                    />
                  </div>
                )}
              </>
            )}{" "}
            {error && <p className="text-white mt-8">{error}</p>}
            {success && (
              <p className="text-white m2-2 font-bold text-3xl">{success}</p>
            )}
            {!success && (
              <div
                onClick={() => {
                  sendForm();
                }}
                className="text-center"
              >
                <button
                  type="submit"
                  className="text-pink bg-white rounded-full w-full py-3 mt-9 transition-all duration-300 ease-in hover:shadow-xl"
                >
                  Enviar
                </button>
              </div>
            )}
          </div>
          <div className="w-5/12">
            <img src={otraOportunidad} alt="Golden retriever triste"></img>
          </div>
        </div>
        <div className="w-full p-content"></div>
      </div>
    );
  }
);

export default NotFoundForm;
