import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { authFetch } from "../../utils/apiRequest";

import moment from "moment";
import "moment/locale/es";
import Modal from "../../components/Modal";
import { ESTADOS_ORDENES } from "../../utils/constants";
import { useSelector } from "react-redux";
moment.updateLocale("es");

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function AprobarPage({ user }) {
  const { idPedido } = useParams();

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [approveReject, setApproveReject] = useState(-1);
  const [referenceUrl, setReferenceUrl] = useState(null);
  const { token } = useSelector((store) => store.app.user);

  const query = useQuery();

  useEffect(() => {
    const fromUrl = query.get("desde");
    if (fromUrl) {
      setReferenceUrl(fromUrl);
    }
  }, [query]);

  const fetchOrder = useCallback(async () => {
    setLoading(true);
    const orderFetch = await authFetch(
      `ventas/${idPedido}?filter={"include":[{"relation":"metodoPagoUtilizado"},{"relation":"metodoEntregaUtilizado"},{"relation":"estado"},{"relation":"usuario"},{"relation":"lineas","scope":{"include":[{"relation":"producto"}]}}]}`,
      null,
      "GET",
      token
    );

    const order = await orderFetch.json();
    setOrder(order);
    setLoading(false);
  }, [idPedido, token]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrder();
  }, [fetchOrder]);

  const updateOrder = async () => {
    setLoading(true);
    if (approveReject === 1) {
      await authFetch(
        `ventas/${idPedido}`,
        {
          idEstadoVenta: ESTADOS_ORDENES.PREPARANDO_ORDEN,
        },
        "PATCH",
        token
      );
    } else {
      await authFetch(
        `ventas/${idPedido}`,
        {
          idEstadoVenta: ESTADOS_ORDENES.RECHAZADA_POR_ASOCIACION,
        },
        "PATCH",
        token
      );
    }
    fetchOrder();
  };

  return !order ? (
    <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white">
      <Spinner />
    </div>
  ) : (
    <div className="w-full p-content pb-16">
      {loading && <Spinner />}
      <Modal
        backgroundColor="bg-purple"
        closeModalFunction={() => {
          setApproveReject(-1);
          setShowConfirmationModal(false);
        }}
        open={showConfirmationModal}
      >
        <div className="text-white p-8 pt-16 overflow-y-scroll h-full text-justify">
          <p className="mb-4">
            Realmente desea {approveReject === 0 ? "rechazar" : "aprobar"} la
            Orden #{idPedido}. Esta acción no se podrá deshacer.
          </p>
          <div className="w-full flex flex-wrap justify-center sm:flex-nowrap sm:justify-end mt-4 gap-2">
            <button
              onClick={() => {
                setApproveReject(-1);
                setShowConfirmationModal(false);
              }}
              className="bg-white text-purple py-2 px-8 rounded-full"
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                updateOrder();
                setShowConfirmationModal(false);
              }}
              className="bg-orange text-white py-2 px-8 rounded-full"
            >
              Confirmar
            </button>
          </div>
        </div>
      </Modal>
      <div>
        <p className="text-2xl font-bold mb-4">
          {referenceUrl === "checkout"
            ? `Orden #${order.id} ingresada exitosamente`
            : `Orden #${order.id}`}
        </p>
        <div className="w-full ring-1 ring-purple rounded-lg mb-4 p-6">
          <p className="text-lg font-bold mb-4">Información General</p>
          <p className="mb-2">
            Fecha del pedido:{" "}
            <span className="font-medium">
              {moment(order.fechaIngreso).format(
                "dddd D [de] MMMM [a la(s)] hh:mm a"
              )}
            </span>
          </p>
          <p className="mb-2">
            Estado: <span className="font-medium">{order.estado.valor}</span>
          </p>
          <p className="mb-2">
            Metodo de Pago:{" "}
            <span className="font-medium">
              {order.metodoPagoUtilizado.nombre}
            </span>
          </p>
          <p className="mb-2">
            Metodo de Entrega:{" "}
            <span className="font-medium">
              {order.metodoEntregaUtilizado.nombre}
            </span>
          </p>
          {order.usuario.nombre && (
            <p className="mb-2">
              Cliente:{" "}
              <span className="font-medium">
                {order.usuario.nombre}
                {order.usuario.apellido1 ? ` ${order.usuario.apellido1}` : ""}
              </span>
            </p>
          )}
          {order.usuario.nombre && (
            <p className="mb-2">
              Identificación de Asociado:{" "}
              <span className="font-medium">
                {order.usuario.idEnOrganizacion}
              </span>
            </p>
          )}
          {order.notasEntrega && (
            <p className="mb-2">
              Notas: <span className="font-medium">{order.notasEntrega}</span>
            </p>
          )}
          {order.telefono ? (
            <p className="mb-2">
              Telefono:{" "}
              <a
                className="text-cyan cursor-pointer"
                href={`https://api.whatsapp.com/send?phone=${order.codigoPaisTelefono}${order.telefono}`}
                target="_blank"
                rel="noreferrer"
              >
                {order.telefono}
              </a>
            </p>
          ) : (
            <p className="mb-2">
              Telefono:{" "}
              <a
                className="text-cyan cursor-pointer"
                href={`https://api.whatsapp.com/send?phone=${order.usuario.codigoPaisTelefono}${order.usuario.telefono}`}
                target="_blank"
                rel="noreferrer"
              >
                {order.usuario.telefono}
              </a>
            </p>
          )}
          <p className="mb-2">
            Subtotal:{" "}
            <span className="font-medium">
              {Intl.NumberFormat("es-CR", {
                style: "currency",
                currency: "CRC",
              }).format(order.totalConImpuestos / 1.13)}
            </span>
          </p>
          <p className="mb-2">
            Transporte:{" "}
            <span className="font-medium">
              {order.totalDeTransporte
                ? Intl.NumberFormat("es-CR", {
                    style: "currency",
                    currency: "CRC",
                  }).format(order.totalDeTransporte / 1.13)
                : "Gratis"}
            </span>
          </p>
          {order.descuentoGlobal > 0 && (
            <p className="mb-2">
              Descuento:{" "}
              <span className="font-medium">
                {Intl.NumberFormat("es-CR", {
                  style: "currency",
                  currency: "CRC",
                }).format(order.descuentoGlobal)}
              </span>
            </p>
          )}
          <p className="mb-2">
            I.V.A:{" "}
            <span className="font-medium">
              {Intl.NumberFormat("es-CR", {
                style: "currency",
                currency: "CRC",
              }).format((order.totalConTransporte * 13) / 113)}
            </span>
          </p>
          <p className="mb-2">
            Total:{" "}
            <span className="font-medium">
              {Intl.NumberFormat("es-CR", {
                style: "currency",
                currency: "CRC",
              }).format(order.totalConTransporte)}
            </span>
          </p>
          {order.idEstadoVenta ===
            ESTADOS_ORDENES.EN_APROBACION_POR_ASOCIACION && (
            <div className="mt-6 flex flex-wrap gap-2 justify-end">
              <button
                onClick={() => {
                  setApproveReject(0);
                  setShowConfirmationModal(true);
                }}
                className=" bg-pink py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
              >
                Rechazar
              </button>
              <button
                onClick={() => {
                  setApproveReject(1);
                  setShowConfirmationModal(true);
                }}
                className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
              >
                Aprobar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AprobarPage;
