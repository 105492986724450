import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import NotFoundForm from "../../components/NotFoundForm";
import ProductPrice from "../../components/ProductPrice";
import NumberSteper from "../../components/NumberSteper";
import { addToCart, removeFromCart } from "../../slices/appSlice";
import ProductName from "../../components/ProductName";
import { singleFetch } from "../../utils/apiRequest";
import { CATEGORIAS } from "../../utils/constants";

import moment from "moment";
import "moment/locale/es";
import { Helmet } from "react-helmet";
import { PickerVariaciones } from "../../components/PickerVariaciones";
moment.updateLocale("es");

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const getProductTitle = (product) => {
  return `${product.marca} ${product.nombreTienda}${" "}${
    product.pesoGramos < 1000
      ? `${product.pesoGramos} gramos`
      : `${(product.pesoGramos / 1000).toFixed(2)} Kg`
  }`;
};

const numberToFraction = (amount) => {
  // This is a whole number and doesn't need modification.
  if (parseFloat(amount) === parseInt(amount)) {
    return amount;
  }
  // Next 12 lines are cribbed from https://stackoverflow.com/a/23575406.
  var gcd = function (a, b) {
    if (b < 0.0000001) {
      return a;
    }
    return gcd(b, Math.floor(a % b));
  };
  var len = amount.toString().length - 2;
  var denominator = Math.pow(10, len);
  var numerator = amount * denominator;
  var divisor = gcd(numerator, denominator);
  numerator /= divisor;
  denominator /= divisor;
  var base = 0;
  // In a scenario like 3/2, convert to 1 1/2
  // by pulling out the base number and reducing the numerator.
  if (numerator > denominator) {
    base = Math.floor(numerator / denominator);
    numerator -= base * denominator;
  }
  amount = Math.floor(numerator) + "/" + Math.floor(denominator);
  if (base) {
    amount = base + " " + amount;
  }
  return amount;
};

export function FamiliaPage({ user, cart }) {
  const navigate = useNavigate();
  const { idFamilia } = useParams();

  const notFoundFormRef = useRef(null);

  /**Familias productos state */
  const [familyProduct, setFamilyProduct] = useState(null);
  /**Familias productos state */

  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedProduct, setRelatedProduct] = useState(null);
  const [idCategoria, setIdCategoria] = useState(null);
  const [idMarca, setIdMarca] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [petName, setPetName] = useState("");
  const [allFilter, setAllFilter] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [dailyConsumption, setDailyConsumption] = useState(0);
  const [estimatedOutOfFoodDate, setEstimatedOutOfFoodDate] = useState("");
  const [estimatedOutOfFoodInDays, setEstimatedOutOfFoodInDays] =
    useState(null);
  const [whatsappConfirmation, setWhatsappConfirmation] = useState(false);
  const [pleaseSetQuantity, setPleaseSetQuantity] = useState(false);
  const [
    showAlertOfMaxQuantityInStockExceeded,
    setShowAlertOfMaxQuantityInStockExceeded,
  ] = useState(false);

  const [setshowAlertOfMaxQuanitityInDiscountExceeded] = useState(false);

  const [showProductNameInNotFoundForm, setShowProductNameInNotFoundForm] =
    useState(false);

  const dispatch = useDispatch();

  const query = useQuery();

  const fetchProduct = async (idProducto) => {
    const data = await singleFetch(`producto-detalles/${idProducto}`);
    const dataJSON = await data.json();
    setProduct(dataJSON);

    if (dataJSON.idCategoria === CATEGORIAS.ALIMENTO_SECO) {
      const relatedProducts = await singleFetch(
        `producto-detalles?filter={"order":["razonPrecioGramos desc"],"where":{"and":[{"idCategoria":{"neq":${CATEGORIAS.ALIMENTO_SECO}}},{"especie":"${dataJSON.especie}"}]}}`
      );
      const relatedProductsJSON = await relatedProducts.json();
      setRelatedProducts(relatedProductsJSON);
      setRelatedProduct(null);
    } else {
      setRelatedProducts([]);
    }
  };
  const fetchFamilia = useCallback(async () => {
    const data = await singleFetch(`producto-detalles/${idFamilia}`);
    const dataJSON = await data.json();
    setProduct(dataJSON);

    const dataProductFamilia = await singleFetch(
      `familias/structured/${idFamilia}`
    );
    const dataProductFamiliaJSON = await dataProductFamilia.json();
    setFamilyProduct(dataProductFamiliaJSON);

    if (dataJSON.idCategoria === CATEGORIAS.ALIMENTO_SECO) {
      const relatedProducts = await singleFetch(
        `producto-detalles?filter={"order":["razonPrecioGramos desc"],"where":{"and":[{"idCategoria":{"neq":${CATEGORIAS.ALIMENTO_SECO}}},{"especie":"${dataJSON.especie}"}]}}`
      );
      const relatedProductsJSON = await relatedProducts.json();
      setRelatedProducts(relatedProductsJSON);
      setRelatedProduct(null);
    } else {
      setRelatedProducts([]);
    }
    window.scrollTo(0, 0);
  }, [idFamilia]);

  useEffect(() => {
    if (idFamilia) {
      fetchFamilia(idFamilia);
      setIdCategoria(query.get("categoria"));
      setRelatedProduct(query.get("relatedProduct"));
      setIdMarca(query.get("marca"));
      setSearchQuery(query.get("query"));
      setAllFilter(query.get("filtro"));
    }
  }, [idFamilia, query, fetchFamilia]);

  useEffect(() => {
    if (cart.products[idFamilia]) {
      setQuantity(cart.products[idFamilia].quantity);
      setDailyConsumption(cart.products[idFamilia].dailyConsumption);
      setEstimatedOutOfFoodDate(
        cart.products[idFamilia].estimatedOutOfFoodDate
          ? new Date(cart.products[idFamilia].estimatedOutOfFoodDate)
          : ""
      );
      setEstimatedOutOfFoodInDays(
        cart.products[idFamilia].estimatedOutOfFoodInDays
      );
      setPetName(cart.products[idFamilia].petName);
    }
  }, [cart.products, idFamilia]);

  useEffect(() => {
    setWhatsappConfirmation(cart.whatsappConfirmation);
  }, [cart.whatsappConfirmation]);

  const goBack = () => {
    if (relatedProduct) {
      navigate(`/tienda/producto/${relatedProduct}`);
    } else {
      if (idCategoria === "todos") {
        navigate(
          `/tienda/${idCategoria}${allFilter ? `?filtro=${allFilter}` : ""}`
        );
      } else {
        if (idCategoria && idMarca) {
          navigate(`/tienda/categoria/${idCategoria}/marca/${idMarca}`);
        } else {
          if (searchQuery) {
            navigate(`/tienda/busqueda?query=${searchQuery}`);
          } else {
            navigate(`/tienda`);
          }
        }
      }
    }
  };

  const changeQuantity = (newValue) => {
    if (product.cantidadEnBodega === 0 && newValue === 1) {
      setShowProductNameInNotFoundForm(true);
      notFoundFormRef.current.scrollIntoView();
    }
    setShowAlertOfMaxQuantityInStockExceeded(false);
    if (!Number.isNaN(newValue)) {
      if (quantity === 0 && newValue < 0) {
        return;
      } else {
        setPleaseSetQuantity(false);
        if (product.descuentos[0]) {
          if (quantity + newValue <= product.unidadesDisponiblesDescuento) {
            setQuantity(quantity + newValue);
            if (dailyConsumption) {
              changeDailyConsumption(0, true, quantity + newValue);
            }
          } else {
            setShowAlertOfMaxQuantityInStockExceeded(true);
          }
        } else {
          if (quantity + newValue <= product.cantidadEnBodega) {
            setQuantity(quantity + newValue);
            if (dailyConsumption) {
              changeDailyConsumption(0, true, quantity + newValue);
            }
          } else {
            setShowAlertOfMaxQuantityInStockExceeded(true);
          }
        }
      }
    }
  };

  const changeDailyConsumption = (newValue, quantityChanged, newQuantity) => {
    if (dailyConsumption === 0 && newValue <= 0 && !quantityChanged) {
      return;
    } else {
      setDailyConsumption(dailyConsumption + newValue);
      const daysUntilOutOfFood = Math.floor(
        ((quantityChanged ? newQuantity : quantity) * product.pesoGramos) /
          ((dailyConsumption + newValue) * 100)
      );
      const finishDate = new Date();
      finishDate.setDate(finishDate.getDate() + daysUntilOutOfFood);
      setEstimatedOutOfFoodDate(finishDate);
      setEstimatedOutOfFoodInDays(daysUntilOutOfFood);
    }
  };
  return (
    <div>
      {!product ? (
        <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white">
          <Spinner />
        </div>
      ) : (
        <div className="w-full p-content pt-4 pb-16">
          <Helmet>
            <title>{getProductTitle(product)}</title>
            <meta name="description" content={getProductTitle(product)} />
          </Helmet>
          <meta property="product:retailer_item_id" content={product.id} />
          <meta property="og:description" content={getProductTitle(product)} />
          <meta property="og:title" content={getProductTitle(product)} />
          <meta
            property="og:url"
            content={`https://ticolitas.com/tienda/producto/${product.id}`}
          />
          <meta property="og:image" content={product.fotoPrincipal} />
          <meta property="product:brand" content={product.marca} />
          <meta
            property="product:availability"
            content={
              product.cantidadEnBodega > 0 ||
              product.unidadesDisponiblesDescuento > 0
                ? "in stock"
                : "out of stock"
            }
          />
          <meta property="product:condition" content="new" />
          <meta
            property="product:price:amount"
            content={
              product.descuento
                ? (product.precioVenta * product.descuento).toFixed(2)
                : product.precioVenta
            }
          />
          <meta property="product:price:currency" content="CRC" />
          <div className="flex w-full items-center mb-4">
            <div className="w-full">
              <button
                onClick={goBack}
                className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right"
              >
                {"<"} Volver
              </button>
            </div>
          </div>
          <div className="w-full flex flex-wrap">
            <div className="w-full lg:w-1/2">
              <ProductName
                showPrescription
                product={product}
                additionalStyle="block lg:hidden font-semibold"
              />
              <div className="px-4 flex justify-center items-center">
                <img
                  className="max-h-[400px]"
                  src={product.fotoPrincipal}
                  alt={`Foto de ${product.nombreTienda}`}
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2">
              <ProductName
                showPrescription
                product={product}
                additionalStyle="hidden lg:block font-semibold"
              />
              {familyProduct && familyProduct.hasOwnProperty("variaciones") && (
                <PickerVariaciones
                  variaciones={familyProduct.variaciones}
                  productos={familyProduct.productos}
                  fetchProduct={fetchProduct}
                  idFamilia={idFamilia}
                  fetchFamilia={fetchFamilia}
                  productToFecth={product}
                />
              )}
              {product.precioVenta && <ProductPrice product={product} />}
              {user &&
                user.token &&
                (_.intersection(user.roles, [
                  "admin",
                  "dependiente",
                  "administradorTienda",
                ]).length > 0 ||
                  user.roles.indexOf("superAdmin") > -1) && (
                  <div>
                    <div className="mt-4">
                      {product.calcularFechaFinalConsumo === 1 && (
                        <div className="mt-4 w-full">
                          <p className="text-listingProductGray text-lg">
                            {new Intl.NumberFormat("es-CR", {
                              style: "currency",
                              currency: "CRC",
                            }).format(
                              (product.precioVenta * (product.descuento || 1)) /
                                (product.pesoGramos / 1000)
                            )}
                          </p>
                          <span className="text-listingProductGray text-xs">
                            {" "}
                            por kilo
                          </span>
                        </div>
                      )}
                      {product.precioVenta ? (
                        <div>
                          {" "}
                          <p className="w-full text-sm text-listingProductGray mt-2">
                            {"Stock: "}
                            <br />
                            <span className="font-bold">
                              {product.cantidadEnBodega || 0}
                            </span>
                          </p>
                          <p className="w-full text-sm text-listingProductGray mt-2">
                            {"Unidades disponibles en precio de descuento: "}
                            <br />
                            <span className="font-bold">
                              {product.unidadesDisponiblesDescuento === null
                                ? "Hasta agotar existencias"
                                : product.unidadesDisponiblesDescuento}
                            </span>
                          </p>
                          <p className="w-full text-sm text-listingProductGray my-2">
                            {"Código de proveedor"}
                            <br />
                            <span className="font-bold">
                              {product.codigoProveedor}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <p className="text-pink/90 font-semibold text-xl">
                          Porfavor seleccione una variación *
                        </p>
                      )}
                    </div>
                  </div>
                )}
              {product.precioVenta && (
                <div className="mt-4">
                  <div className="mt-4">
                    <NumberSteper
                      label="¿Cuántas unidades te gustaría comprar?:"
                      value={quantity}
                      onChangeValue={changeQuantity}
                      negativeStepSize={-1}
                      positiveStepSize={1}
                    />

                    {setshowAlertOfMaxQuanitityInDiscountExceeded && (
                      <p className="text-sm text-pink">
                        {`El precio de este producto ha incrementado como consecuencia de temas logísticos mundiales. En TiColitas te garantizamos mantener el precio hasta agotar nuestro inventario actual de ${
                          product.unidadesDisponiblesDescuento === 1
                            ? "1 unidad"
                            : `${product.unidadesDisponiblesDescuento} unidades`
                        }`}
                      </p>
                    )}

                    {showAlertOfMaxQuantityInStockExceeded &&
                      (!product.descuento || product.descuento === 0) && (
                        <p className="text-sm text-pink">
                          Lo sentimos, pronto tendremos más unidades disponibles
                          de este artículo.
                        </p>
                      )}
                  </div>
                  {product.calcularFechaFinalConsumo === 1 && (
                    <div className="mt-4">
                      <NumberSteper
                        label="Queremos hacerte la vida más sencilla. Por favor, indicanos el consumo diario aproximado de alimento en tazas (1 taza equivale a 236 mL) que te indicó tu veterinario, o el que está indicado por el fabricante en el empaque, para recordarte antes de que se te agote:"
                        value={`${numberToFraction(dailyConsumption)} tazas`}
                        onChangeValue={changeDailyConsumption}
                        negativeStepSize={-0.25}
                        positiveStepSize={0.25}
                      />
                    </div>
                  )}
                  {dailyConsumption > 0 && (
                    <>
                      <div className="mt-4">
                        <label
                          className="w-full text-borderGray"
                          htmlFor="cantidad"
                        >
                          Este alimento rendirá aproximadamente hasta el:
                        </label>
                        <div className="w-full relative inline-block">
                          <div
                            className="flex items-center w-full px-8 rounded-full border border-borderGray h-14 font-body outline-none justify-center"
                            type="number"
                            id="cantidad"
                            name="cantidad"
                          >
                            {estimatedOutOfFoodInDays <= 0 ? (
                              quantity === 0 ? (
                                "Indicá una cantidad de alimento"
                              ) : (
                                "Necesitás un alimento con mayor contenido"
                              )
                            ) : (
                              <>
                                {moment(estimatedOutOfFoodDate).format(
                                  "dddd D [de] MMMM"
                                )}{" "}
                                {estimatedOutOfFoodInDays === 1
                                  ? `(1 día)`
                                  : `(${estimatedOutOfFoodInDays} días)`}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="flex items-center">
                          <input
                            onClick={() => {
                              setWhatsappConfirmation(!whatsappConfirmation);
                            }}
                            defaultChecked={whatsappConfirmation}
                            type="checkbox"
                            id="whatsappConfirmation"
                            name="whatsappConfirmation"
                            value="yes"
                            className="opacity-0 absolute h-8 w-8"
                          />
                          <div className="bg-white border-2 rounded-md border-borderGray w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                            <svg
                              className={`fill-current ${
                                whatsappConfirmation ? "block" : "hidden"
                              } w-3 h-3 text-blue-600 pointer-events-none`}
                              version="1.1"
                              viewBox="0 0 17 12"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="none" fillRule="evenodd">
                                <g
                                  transform="translate(-9 -11)"
                                  fill="#19D3C5"
                                  fillRule="nonzero"
                                >
                                  <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                                </g>
                              </g>
                            </svg>
                          </div>
                          <label className="ml-2 text-borderGray">
                            Notificarme por WhatsApp 3 días antes de la fecha
                            arriba indicada para coordinar un nuevo pedido.{" "}
                            {/*
													  <span className="text-cyan font-bold">
														Conocé aquí como te beneficias con esto.
													  </span>
														*/}
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="mt-4">
                    <label
                      htmlFor="lastName"
                      className="text-base text-borderGray"
                    >
                      Ingresá el nombre de tu mascota para personalizar tu
                      pedido:
                    </label>
                    <input
                      id="name"
                      name="name"
                      value={petName}
                      placeholder="Ej: Tuti, Max, Oso"
                      className="w-full rounded-3xl my-2 h-12 p-6 border border-borderGray"
                      onChange={(evt) => {
                        setPetName(evt.target.value);
                      }}
                    />
                  </div>
                  <div className="flex w-full items-center mt-6">
                    <div className="w-full">
                      {pleaseSetQuantity && (
                        <p className="text-sm text-pink">
                          Por favor indique la cantidad de producto antes de
                          agregarlo.
                        </p>
                      )}
                      <button
                        onClick={() => {
                          if (quantity > 0) {
                            setShowAlertOfMaxQuantityInStockExceeded(false);
                            dispatch(
                              addToCart({
                                product,
                                quantity,
                                petName,
                                dailyConsumption,
                                whatsappConfirmation,
                                estimatedOutOfFoodDate: estimatedOutOfFoodDate
                                  ? estimatedOutOfFoodDate.toISOString()
                                  : "",
                              })
                            );
                          } else {
                            if (cart.products[product.id]) {
                              dispatch(removeFromCart(product.id));
                            } else {
                              setPleaseSetQuantity(true);
                            }
                          }
                        }}
                        className="bg-purple text-white py-2 px-8 rounded-full"
                      >
                        {cart.products[product.id] && quantity === 0
                          ? "Eliminar de la tacita"
                          : "Agregar a la tacita"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-8">
            {relatedProducts.length > 0 && (
              <div>
                <div className="w-full flex justify-center my-12 b-4">
                  <p className="text-listingProductGray text-lg font-bold">
                    Productos Relacionados
                  </p>
                </div>
                <div className="flex overflow-x-auto">
                  {relatedProducts.map((p) => {
                    return (
                      <Link
                        key={`producto_${p.id}`}
                        to={`/tienda/producto/${p.id}?relatedProduct=${idFamilia}`}
                        className={`cursor-pointer flex flex-wrap flex-[0_0_calc(50%-0.5rem)] sm:flex-[0_0_calc(33.3333333333%-0.5rem)] md:flex-[0_0_calc(33.3333333%-0.5rem)] lg:flex-[0_0_calc(33.3333333%-0.5rem)] items-center bg-backgroundGray py-4 px-8 rounded-md`}
                      >
                        <div
                          className={`flex flex-wrap items-center bg-backgroundGray py-4 px-8 rounded-md`}
                        >
                          {p.fotoPrincipal ? (
                            <img
                              className="rounded-md"
                              src={p.fotoPrincipal}
                              alt={`Foto de ${p.nombreTienda}`}
                            />
                          ) : (
                            <p className="text-listingProductGray font-bold">
                              Fotografía no disponible
                            </p>
                          )}
                          <p className="w-full text-sm text-listingProductGray mt-2 font-bold">
                            {p.nombreTienda}
                          </p>
                          {p.prescrito === 1 && (
                            <span className="text-pink">
                              Alimento de Prescripción
                            </span>
                          )}
                          {p.pesoGramos && (
                            <p className="w-full text-sm text-listingProductGray mt-2">
                              {"Presentación: "}
                              <br />
                              <span className="font-bold">
                                {p.pesoGramos < 1000
                                  ? `${p.pesoGramos} gramos`
                                  : `${(p.pesoGramos / 1000).toFixed(2)} Kg`}
                              </span>
                            </p>
                          )}
                          <div className="flex flex-wrap">
                            <ProductPrice hideDiscountText product={p} />
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {product.description && (
            <div className="w-full">
              <div className="w-full my-6">
                <span className="border-b-2 border-orange text-listingProductGray px-2">
                  Descripción
                </span>
              </div>
              <div className="w-full text-listingProductGray">
                <p>{product.descripcion}</p>
              </div>
            </div>
          )}
        </div>
      )}
      <NotFoundForm
        ref={notFoundFormRef}
        product={showProductNameInNotFoundForm ? product : null}
      />
    </div>
  );
}

export default FamiliaPage;
