import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { CierreContext } from "../../containers/CierresPage";
import { authFetch } from "../../utils/apiRequest";
import { numberToColonesFormat } from "../../utils/formatNumbers";

export default function MetodoPagoCard({
  cierreMonto,
  idCierre,
  editable = true,
}) {
  const { actualizarCierre } = useContext(CierreContext);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((store) => store.app.user);

  const [montoRecibido, setMontoRecibido] = useState(
    cierreMonto.monto_recibido
  );

  useEffect(() => {
    setMontoRecibido(cierreMonto.monto_recibido);
    return () => {
      setMontoRecibido();
    };
  }, [cierreMonto]);

  const cerrarDia = async () => {
    if (isNaN(Number(montoRecibido))) {
      setError("El campo debe ser un número válido");
    } else {
      setError();
      setLoading(true);
      await authFetch(
        `cierres/${idCierre}/metodos-pagos/${cierreMonto.id_metodo_pago}`,
        {
          montoRecibido: Number(montoRecibido),
        },
        "PATCH",
        token
      );
      await actualizarCierre();
      setLoading(false);
    }
  };

  if (cierreMonto.monto_a_recolectar === 0) {
    return null;
  }

  return (
    <div className="w-full border border-purple rounded-md p-5 flex flex-col gap-3">
      <h2 className="text-2xl font-semibold">{cierreMonto.nombre}</h2>

      <div className="flex flex-col gap-2">
        <p>
          Total a recolectar:{" "}
          <span className="font-semibold">
            {numberToColonesFormat(Number(cierreMonto.monto_a_recolectar))}
          </span>
        </p>
        <p>
          Total recolectado:{" "}
          <span className="font-semibold">
            {numberToColonesFormat(Number(cierreMonto.monto_recolectado))}
          </span>
        </p>
        <p>
          Pendiente de recolectar:{" "}
          <span className="font-semibold">
            {numberToColonesFormat(Number(cierreMonto.monto_pendiente))}
          </span>
        </p>
      </div>

      {editable && (
        <div className="mt-2 flex flex-col gap-4">
          <div className="flex flex-col gap-3">
            <label>Recibido por el administrador:</label>
            <input
              className="border border-purple p-4 rounded-full outline-purple"
              disabled={Boolean(cierreMonto.finalizado)}
              value={montoRecibido}
              onChange={(e) => setMontoRecibido(e.target.value)}
            />
            {error && <span className="text-pink">{error}</span>}
          </div>

          {cierreMonto.finalizado === 0 && (
            <div className="flex w-full justify-end">
              <button
                className="bg-purple text-white px-6 py-3 rounded-full mt-2 flex items-center gap-2"
                onClick={() => cerrarDia()}
              >
                {loading && <ClipLoader color="#fff" />}
                Cerrar día
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
