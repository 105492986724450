import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Spinner from "../../components/Spinner";
import { authFetch, singleFetch } from "../../utils/apiRequest";
import Pagination from "../../components/Pagination";

import CheckboxInput from "../../components/CheckboxInput";
import { useSelector } from "react-redux";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const PAGE_SIZE = 10;

export const BodegaInventario = () => {
  const { idBodega } = useParams();
  const [products, setProducts] = useState(null);
  const [warehouse, setWarehouse] = useState({});
  const [brands, setBrands] = useState([]);
  const [brandFilter, setBrandFilter] = useState([]);

  const query = useQuery();

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const { token, roles } = useSelector((store) => store.app.user);

  useEffect(() => {
    const filterFromParam = query.get("filtro");
    if (filterFromParam) {
      setBrandFilter(JSON.parse(filterFromParam));
    }
  }, [query]);

  const fetchWarehouse = useCallback(async () => {
    const warehouseRequest = await authFetch(
      `bodegas/${idBodega}`,
      null,
      "GET",
      token
    );
    const warehouseData = await warehouseRequest.json();
    setWarehouse(warehouseData);
  }, [idBodega, token]);

  const fetchBrands = useCallback(async () => {
    setLoading(true);

    const brandsRequest = await singleFetch(
      'marcas?filter={"offset": 0,"limit": 300,"skip": 0,"order": ["nombre asc"]}'
    );
    const brandsData = await brandsRequest.json();
    setBrands(brandsData);

    const filterFromParam = query.get("filtro");
    if (filterFromParam) {
      setBrandFilter(JSON.parse(filterFromParam));
    } else {
      setBrandFilter(["all"].concat(_.map(brandsData, "nombreTienda")));
    }

    setLoading(false);
  }, [query]);

  useEffect(() => {
    async function fetchData() {
      const bodegaFecth = await singleFetch(
        `bodega-productos/bodega/${idBodega}/productos?filter={
			  "limit": ${PAGE_SIZE},
			  "skip": ${page * PAGE_SIZE},
			  "order": ["marca", "nombre", "pesoGramos"]
			  ${
          brandFilter.length === 0 || brandFilter[0] === "all"
            ? brandFilter.length === 0
              ? ', "where": { "marca": ""}'
              : ""
            : `, "where": { "marca": { "inq": ${JSON.stringify(brandFilter)} }}`
        }
			}`
      );
      const bodegaData = await bodegaFecth.json();
      setProducts(bodegaData);
      const totalbodegaFecth = await singleFetch(
        `bodega-productos/bodega/${idBodega}/productos/count${
          brandFilter.length === 0 || brandFilter[0] === "all"
            ? brandFilter.length === 0
              ? '?where={ "marca": ""}'
              : ""
            : `?where={ "marca": { "inq": ${JSON.stringify(brandFilter)} }}`
        }`
      );
      const totalbodegasData = await totalbodegaFecth.json();
      setTotalPages(Math.ceil(totalbodegasData.count / PAGE_SIZE));
      setTotalCount(totalbodegasData.count);
    }
    fetchData();
  }, [brandFilter, idBodega, page]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBrands();
    fetchWarehouse();
  }, [fetchBrands, fetchWarehouse]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const canWrite = roles.includes("admin");

  return (
    <div>
      {loading && <Spinner />}
      <div className="flex flex-wrap w-full px-16 pt-4 pb-16">
        <div className="w-full flex flex-wrap lg:flex-nowrap">
          <div className="w-full flex flex-wrap lg:block lg:flex-nowrap lg:w-3/12 mb-4">
            <p className="mb-4 w-full">Marcas</p>
            <CheckboxInput
              containerClass={"mb-2"}
              checked={brandFilter.includes("all")}
              onCheckChange={() => {
                const brandIndex = brandFilter.indexOf("all");
                setPage(0);
                if (brandIndex === -1) {
                  brandFilter.push("all");
                  setBrandFilter(["all"].concat(_.map(brands, "nombreTienda")));
                } else {
                  setBrandFilter([]);
                }
              }}
              labelColor="text-base"
              label={"Todas las marcas"}
            />
            {brands.map((b, index) => {
              const isChecked = brandFilter.indexOf(b.nombreTienda) !== -1;
              return (
                <CheckboxInput
                  key={`filter_${index}`}
                  containerClass={"mb-2"}
                  checked={isChecked}
                  onCheckChange={() => {
                    const newBrandFilter = _.clone(brandFilter);
                    setPage(0);
                    if (!isChecked) {
                      newBrandFilter.push(b.nombreTienda);
                      setBrandFilter(newBrandFilter);
                    } else {
                      const brandIndex = newBrandFilter.indexOf(b.nombreTienda);
                      newBrandFilter.splice(brandIndex, 1);
                      const allIndex = newBrandFilter.indexOf("all");
                      if (allIndex !== -1) {
                        newBrandFilter.splice(allIndex, 1);
                      }
                      setBrandFilter(newBrandFilter);
                    }
                  }}
                  labelColor="text-base"
                  label={b.nombreTienda}
                />
              );
            })}
          </div>

          {products && products.length > 0 ? (
            <div className="w-full lg:w-9/12">
              <div className="w-full">
                <div>
                  <div className="flex w-full items-center">
                    <div className="w-full flex justify-between">
                      <div>
                        <p className="text-2xl font-bold mb-4">
                          {warehouse.nombre} (Total: {totalCount})
                        </p>
                        {canWrite && (
                          <Link
                            to={`/bodegas/inventario/${idBodega}/producto/${"nuevo"}`}
                          >
                            <button className="bg-cyan text-white py-2 px-8 font-bold rounded-full float-left flex justify-center items-center">
                              + Agregar producto
                            </button>
                          </Link>
                        )}
                      </div>
                      <Link to="/Bodegas">
                        <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                          <IoMdArrowRoundBack className="text-lg" /> Volver
                        </button>
                      </Link>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              <div className="w-full flex flex-wrap mb-8">
                <div className="flex border-b items-center w-full py-3 px-2 gap-2">
                  <div className="w-1/12 font-medium text-sm text-center">
                    Producto
                  </div>
                  <div className="w-2/12 font-medium text-sm text-center">
                    Marca
                  </div>
                  <div className="w-4/12 font-medium text-sm text-center">
                    Nombre en tienda
                  </div>
                  <div className="w-2/12 font-medium text-sm text-center">
                    Peso en gramos
                  </div>
                  <div className="w-2/12 font-medium text-sm text-center">
                    Cantidad en bodega
                  </div>
                  <div className="w-2/12 font-medium text-sm text-center">
                    Cantidad en Transito
                  </div>
                  {canWrite && (
                    <div className="w-1/12 font-medium text-sm text-center">
                      Acción
                    </div>
                  )}
                </div>

                <div className="flex flex-col w-full">
                  {products.map((b, index) => {
                    return (
                      <div
                        className="flex border-b items-center w-full py-3 px-2 gap-2"
                        key={index}
                      >
                        <div className="w-1/12 text-center">
                          <img src={b.fotoPrincipal} alt={b.nombre} />
                        </div>
                        <div className="w-2/12 text-sm text-center">
                          {b.marca}
                        </div>
                        <div className="w-4/12 text-sm text-center">
                          {b.nombre}
                        </div>
                        <div className="w-2/12 text-sm text-center">
                          {b.etiquetaPeso}
                        </div>
                        <div className="w-2/12 text-sm text-center">
                          {b.cantidadEnBodega}
                        </div>
                        <div className="w-2/12 text-sm text-center">
                          {b.cantidadEnTransito}
                        </div>
                        {canWrite && (
                          <Link
                            className="w-1/12 text-center"
                            to={`/bodegas/inventario/${idBodega}/producto/${b.idProducto}`}
                          >
                            <button className="text-center bg-cyan px-5 h-9 rounded-md text-white py-2 cursor-pointer">
                              Editar
                            </button>
                          </Link>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <Pagination
                totalPages={totalPages}
                currentPage={page}
                onPageChange={(page) => {
                  setPage(page);
                }}
              />
            </div>
          ) : (
            <div className="flex justify-center">
              <p className="text-center text-2xl font-bold">No hay productos</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
