import React, { useEffect, useRef, useState, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FloatingWhatsApp from "react-floating-whatsapp";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Cart from "../../components/Cart";
import { singleFetch } from "../../utils/apiRequest";

import HomePage from "../HomePage";
import PromoHills from "../PromoHills";
import TiendaPage from "../TiendaPage";
import NotFoundPage from "../NotFoundPage";
import CategoriaPage from "../CategoriaPage";
import MarcaPage from "../MarcaPage";
import ProductoPage from "../ProductoPage";
import FamiliaPage from "../FamiliaPage";
import CheckoutPage from "../CheckoutPage";
import CuentaDatosPersonalesPage from "../CuentaDatosPersonalesPage";
import InventarioPage from "../InventarioPage";
import LoginPage from "../LoginPage";
import SignUpPage from "../SignUpPage";
import AllProductsPage from "../AllProductsPage";
import BodegaOrdenesPage from "../BodegaOrdenesPage";
import PedidoPage from "../PedidoPage";
import EnviosPage from "../EnviosPage";
import PickUpsPage from "../PickUpsPage";
import SearchPage from "../SearchPage";
import AboutUsPage from "../AboutUsPage";
import NoAutorizadoPage from "../NoAutorizadoPage";
import AgregarPage from "../AgregarPage";
import ClientePedidosPage from "../ClientePedidosPage";
import PedidosPage from "../PedidosPage";
import AprobarPage from "../AprobarPage";
import SorteoPage from "../SorteoPage";

import userIsAuthorized from "../../utils/userIsAuthorized";

import avatar from "../../assets/avatar.svg";
import InventarioProductosPage from "../InventarioProductosPage";
import InventarioProductosNuevoPage from "../InventarioProductosNuevoPage";
import CheckoutTiendaPage from "../CheckoutTiendaPage";
import PromoTermsAndConditions from "../PromoTermsAndConditions";
import CitasPage from "../CitasPage";

import OrdenesCompra from "../OrdenesCompra";
import OrdenesCompraAdd from "../OrdenesCompra/add";
import OrdenesCompraEdit from "../OrdenesCompra/edit";

import SearchPedidos from "../PedidosPage/searchPedidos";
import {
  BodegasForm,
  BodegasPage,
  CategoriaCatalogos,
  CategoriaForm,
  JerarquiaForm,
  JerarquiaPage,
  MarcasForm,
  MarcasPage,
  ProveedoresForm,
  ProveedoresPage,
  TransferenciasForm,
  TransferenciasList,
} from "../inventory";
import { TiendaBodegas } from "../BodegaOrdenesPage/main";
import { MarcarRecibida } from "../OrdenesCompra/MarcarRecibida";
import { NuevaLinea } from "../OrdenesCompra/NuevaLinea";
import { BodegaInventario } from "../BodegasPage/BodegaInventario";
import { FormInventario } from "../BodegasPage/FormInventario";
import { FormInventarioNuevo } from "../BodegasPage/FormInventarioNuevo";
import CatalogsPage from "../CatalogsPage";
import FormCatalogo from "../CatalogsPage/form";
import { CatalogoValores } from "../CatalogsPage/Valores";
import FormValores from "../CatalogsPage/ValoresForm";
import VariationsPage from "../VariationsPage";
import FormVariation from "../VariationsPage/form";
import InventoryFamilyProduct from "../InventoryFamilyPage";
import InventoryFamilyProductForm from "../InventoryFamilyPage/form";
import CierresPage from "../CierresPage";
import RecordatoriosPage from "../RecordatoriosPage";
import PendientesPage from "../PendientesPage";
import NewOrdenBySells from "../OrdenesCompra/addBySells";
import RoutesList from "../RutasPage/components/RoutesList";
import CreateRuta from "../RutasPage/CreateRoute";
import RouteDetail from "../RutasPage/RouteDetail";
import EditRoute from "../RutasPage/EditRoute";
import ClientPage from "../ClientsPage";
import DetailPageClient from "../ClientsPage/DetailPage";
import OrdenesCompraView from "../OrdenesCompra/view";

function PrivateRoute({ children, user, roles }) {
  const userIsLogged = user && user.token;
  if (userIsLogged) {
    if (userIsAuthorized(user.roles, roles)) {
      return children;
    } else {
      return <Navigate to="/no-autorizado" />;
    }
  }
  return <Navigate to="/log-in" />;
}

export default function App() {
  const [alert, setAlert] = useState(null);
  const [menuOptions, setMenuOptions] = useState([]);

  const footer = useRef(null);

  const cart = useSelector((state) => state.app.cart);
  const user = useSelector((state) => state.app.user);
  const showCart = useSelector((state) => state.app.showCart);

  useEffect(() => {
    loadAlert();
    loadMenu();
  }, []);

  const loadAlert = async () => {
    const alertFetch = await singleFetch(
      'alertas?filter={"where":{"active":true}}'
    );
    const alert = await alertFetch.json();
    if (alert[0]) {
      setAlert(alert[0].alerta);
    }
  };

  const loadMenu = () => {
    //const alert = await fetch('http://');
    setMenuOptions([
      {
        link: "/tienda/categoria/1",
        label: "Perros",
      },
      {
        link: "/tienda/categoria/2",
        label: "Gatos",
      },
      {
        link: "/acerca-de",
        label: "Acerca de",
      },
      {
        link: "#contacto",
        label: "Contáctenos",
        onClick: () => {
          footer.current.scrollIntoView();
        },
      },
    ]);
  };

  /**
   * LazyLoad by Inventory Pages
   */

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Header
          user={user}
          cart={cart}
          alert={alert}
          menuOptions={menuOptions}
        />
        <Cart user={user} cart={cart} showCart={showCart} />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/promo-hills" element={<PromoHills />} />
          <Route exact path="/tienda" element={<TiendaPage user={user} />} />
          <Route
            exact
            path="/tienda/todos"
            element={<AllProductsPage cart={cart} />}
          />
          <Route exact path="/acerca-de" element={<AboutUsPage />} />
          <Route
            exact
            path="/reglamento-promo-febrero"
            element={<PromoTermsAndConditions />}
          />
          <Route exact path="/sorteo" element={<SorteoPage />} />
          <Route exact path="/asesorias" element={<CitasPage />} />
          <Route exact path="/citas" element={<CitasPage />} />
          <Route
            exact
            path="/tienda/busqueda"
            element={<SearchPage cart={cart} />}
          />

          <Route
            exact
            path="/tienda/categoria/:idCategoria"
            element={<CategoriaPage />}
          />
          <Route
            exact
            path="/tienda/categoria/:idCategoria/marca/:idMarca"
            element={<MarcaPage />}
          />
          <Route
            exact
            path="/tienda/producto/:idProducto"
            element={<ProductoPage user={user} cart={cart} />}
          />
          <Route
            exact
            path="/tienda/familia/:idFamilia"
            element={<FamiliaPage user={user} cart={cart} />}
          />
          <Route
            exact
            path="/tienda/agregar/:idProducto"
            element={<AgregarPage user={user} cart={cart} />}
          />
          <Route
            exact
            path="/checkout"
            element={
              <CheckoutPage
                cart={cart}
                user={user}
                userIsLogged={user && user.token}
              />
            }
          />
          <Route exact path="/aprobar/:idPedido" element={<AprobarPage />} />
          <Route
            exact
            path="/checkout-pos"
            element={
              <PrivateRoute
                user={user}
                roles={["admin", "dependiente", "administradorTienda"]}
              >
                <CheckoutTiendaPage
                  cart={cart}
                  user={user}
                  userIsLogged={user && user.token}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/cuenta"
            element={
              <PrivateRoute
                user={user}
                roles={["cliente", "bodega", "admin", "administradorTienda"]}
              >
                <CuentaDatosPersonalesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/inventario"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda", "mensajero"]}
              >
                <InventarioPage />
              </PrivateRoute>
            }
          />

          {/**
           * LazyLoad by Inventory Pages
           */}

          <Route
            path="/bodegas"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda", "mensajero"]}
              >
                <BodegasPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/bodegas/inventario/:idBodega"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda", "mensajero"]}
              >
                <BodegaInventario />
              </PrivateRoute>
            }
          />
          <Route
            path="/bodegas/inventario/:idBodega/producto/:idProductoBodega"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <FormInventario />
              </PrivateRoute>
            }
          />
          <Route
            path="/bodegas/inventario/:idBodega/producto/nuevo"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <FormInventarioNuevo />
              </PrivateRoute>
            }
          />

          <Route
            path="/bodegas/:idBodega"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <BodegasForm />
              </PrivateRoute>
            }
          />

          <Route
            path="/proveedores"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <ProveedoresPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/proveedores/:idProveedor"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <ProveedoresForm />
              </PrivateRoute>
            }
          />

          <Route
            path="/transferencias"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <TransferenciasList />
              </PrivateRoute>
            }
          />

          <Route
            path="/transferencias/nueva"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <TransferenciasForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/ordenes"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <OrdenesCompra />
              </PrivateRoute>
            }
          />

          <Route
            path="/rutas"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <RoutesList />
              </PrivateRoute>
            }
          />

          <Route
            path="/rutas/create"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <CreateRuta />
              </PrivateRoute>
            }
          />

          <Route
            path="/rutas/:id"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <RouteDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/rutas/:id/edit"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <EditRoute />
              </PrivateRoute>
            }
          />

          <Route
            path="/ordenes/nuevo"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <OrdenesCompraAdd />
              </PrivateRoute>
            }
          />

          <Route
            path="/ordenes/nuevo/ventas"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <NewOrdenBySells />
              </PrivateRoute>
            }
          />

          <Route
            path="/ordenes/:idOrden"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <OrdenesCompraEdit />
              </PrivateRoute>
            }
          />

          <Route
            path="/ordenes/ver/:idOrden"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <OrdenesCompraView />
              </PrivateRoute>
            }
          />

          <Route
            path="/ordenes/marcar_recibida/:idOrden"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <MarcarRecibida />
              </PrivateRoute>
            }
          />
          <Route
            path="/ordenes/marcar_recibida/:idOrden/lineaNueva"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <NuevaLinea />
              </PrivateRoute>
            }
          />

          <Route
            path="/productos"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <InventarioProductosPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/marcas"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <MarcasPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/marcas/:idMarca"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <MarcasForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/categorias_catalogo"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <CategoriaCatalogos />
              </PrivateRoute>
            }
          />
          <Route
            path="/categorias_catalogo/:idCategoria"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <CategoriaForm />
              </PrivateRoute>
            }
          />

          <Route
            path="/categorias_jerarquia"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <JerarquiaPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/categorias_jerarquia/:idJerarquia"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <JerarquiaForm />
              </PrivateRoute>
            }
          />

          <Route
            path="/variaciones_producto"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <VariationsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/variaciones_producto/:idVariation"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <FormVariation />
              </PrivateRoute>
            }
          />

          <Route
            path="/inventario/familias"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <InventoryFamilyProduct />
              </PrivateRoute>
            }
          />
          <Route
            path="/inventario/familias/:idFamily"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <InventoryFamilyProductForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/inventario/productos/:idProducto"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <InventarioProductosNuevoPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/catalogos"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <CatalogsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/catalogos/valores/:idCatalogo"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <CatalogoValores />
              </PrivateRoute>
            }
          />
          <Route
            path="/catalogos/valores/:idCatalogo/form/:idValor"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <FormValores />
              </PrivateRoute>
            }
          />

          <Route
            path="/catalogos/:idCatalogo"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <FormCatalogo />
              </PrivateRoute>
            }
          />

          <Route
            path="/tienda/bodegas"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <TiendaBodegas />
              </PrivateRoute>
            }
          />
          <Route
            path="/bodegas/:idBodega/ordenes"
            element={
              <PrivateRoute
                user={user}
                roles={["bodega", "admin", "administradorTienda"]}
              >
                <BodegaOrdenesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/envios"
            element={
              <PrivateRoute
                user={user}
                roles={["mensajero", "admin", "administradorTienda"]}
              >
                <EnviosPage user={user} />
              </PrivateRoute>
            }
          />
          <Route
            path="/pick-ups"
            element={
              <PrivateRoute
                user={user}
                roles={["mensajero", "admin", "administradorTienda"]}
              >
                <PickUpsPage user={user} />
              </PrivateRoute>
            }
          />
          <Route
            path="/pedidos"
            element={
              <PrivateRoute
                user={user}
                roles={[
                  "cliente",
                  "bodega",
                  "mensajero",
                  "admin",
                  "administradorTienda",
                ]}
              >
                {user.roles.length === 1 &&
                user.roles.indexOf("cliente") === 0 ? (
                  <ClientePedidosPage cart={cart} user={user} />
                ) : (
                  <PedidosPage user={user} />
                )}
              </PrivateRoute>
            }
          />
          <Route exact path="/pedidos/busqueda" element={<SearchPedidos />} />

          <Route
            path="/pedidos/:idPedido"
            element={
              <PrivateRoute
                user={user}
                roles={[
                  "cliente",
                  "bodega",
                  "mensajero",
                  "admin",
                  "administradorTienda",
                ]}
              >
                <PedidoPage user={user} />
              </PrivateRoute>
            }
          />

          <Route
            path="/cierres"
            element={
              <PrivateRoute
                user={user}
                roles={["admin", "administradorTienda"]}
              >
                <CierresPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/recordatorios"
            element={
              <PrivateRoute
                user={user}
                roles={["admin", "administradorTienda"]}
              >
                <RecordatoriosPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/clientes"
            element={
              <PrivateRoute
                user={user}
                roles={["admin", "administradorTienda", "dependiente"]}
              >
                <ClientPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/clientes/:telefonoCliente"
            element={
              <PrivateRoute
                user={user}
                roles={["admin", "administradorTienda", "dependiente"]}
              >
                <DetailPageClient />
              </PrivateRoute>
            }
          />

          <Route
            path="/pendientes"
            element={
              <PrivateRoute
                user={user}
                roles={["admin", "administradorTienda"]}
              >
                <PendientesPage />
              </PrivateRoute>
            }
          />

          <Route exact path="/log-in" element={<LoginPage />} />
          <Route exact path="/registro" element={<SignUpPage />} />
          <Route exact path="/no-autorizado" element={<NoAutorizadoPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer ref={footer} />

        <FloatingWhatsApp
          phoneNumber="50683133113"
          accountName="TiColitas"
          allowClickAway
          avatar={avatar}
          statusMessage="La Bodega del Alimento"
          placeholder="Escribe un mensaje..."
          chatMessage={`¡Hola! 🐶😸 \n¿Cómo te podemos ayudar?`}
          notification
          notificationDelay={30000}
        />
      </BrowserRouter>
    </Suspense>
  );
}
