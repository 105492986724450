import React, { useCallback, useEffect } from "react";
import Quagga from "quagga";
import { singleFetch } from "../../utils/apiRequest";

const BarcodeScannerComponent = ({ onUpdate }) => {

  const fetchProduct = async (code) => {
    const fetchProductByCodebar = await singleFetch(`productos/count?where={"codigoBarras":"${code}"}`);
    const countOfProducts = await fetchProductByCodebar.json();
    return countOfProducts.count;
  };

  const onDetected = useCallback(async result => {
    if (result && result.codeResult && result.codeResult.format === "ean_13") {
      const foundProduct = await fetchProduct(result.codeResult.code);
      if (foundProduct) {
        Quagga.stop();
        onUpdate(result);
      }
    }
  }, [onUpdate]);

  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          constraints: {
            facingMode: "environment",
          },
        },
        locator: {
          halfSample: true,
          patchSize: "large", // x-small, small, medium, large, x-large
          debug: {
            showCanvas: true,
            showPatches: false,
            showFoundPatches: false,
            showSkeleton: false,
            showLabels: false,
            showPatchLabels: false,
            showRemainingPatchLabels: false,
            boxFromPatches: {
              showTransformed: true,
              showTransformedBox: true,
              showBB: true,
            },
          },
        },
        numOfWorkers: 4,
        decoder: {
          readers: ["ean_reader"],
          debug: {
            drawBoundingBox: true,
            showFrequency: true,
            drawScanline: true,
            showPattern: true,
          },
        },
        locate: true,
      },
      function (err) {
        if (err) {
          return console.log(err);
        }
        Quagga.start();
      }
    );
    Quagga.onDetected(onDetected);
    return function cleanup() {
      Quagga.offDetected(onDetected);
      Quagga.stop();
    };
  }, [onDetected]);

  useEffect(() => {
    return () => {
      return function cleanup() {
        Quagga.stop();
      };
    }
  }, []);

  return <div id="interactive" className="viewport" />;
};

export default BarcodeScannerComponent;
