import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _, { debounce } from "lodash";
import AddressForm from "../../components/AddressForm";
import BillingForm from "../../components/BillingForm";
import Cart from "../../components/Cart";
import CheckboxInput from "../../components/CheckboxInput";
import Modal from "../../components/Modal";
import Select from "../../components/Select";
import { singleFetch, authFetch } from "../../utils/apiRequest";
import SignupForm from "../../components/SignupForm";
import LoginForm from "../../components/LoginForm";
import TermsAndConditions from "../../components/TermsAndConditions";
import TermsAndConditionsGiveaway from "../../components/TermsAndConditionsGiveaway";
import {
  UNIDAD_MEDIDA_UNIDAD,
  USUARIO_TICOLITAS,
  METODOS_ENTREGA,
  DEFAULT_POSITION_IN_SHIPPING_ORDER,
  COMPRA_EN_LINEA,
  HILL_PAWS,
} from "../../utils/constants";
import Spinner from "../../components/Spinner";
import { clearCart } from "../../slices/appSlice";

import {
  setWhatsappReconfirmation,
  setAcceptTermsAndConditions,
  setPrintBill,
  setAddressDetails,
  setSelectedStore,
  setBilling,
  setBillingDetails,
  setPaymentMethod,
  setStores,
  setPaymentMethods,
  setCashAmount,
  setOrderNotes,
  setShippingMethods,
  setShippingMethod,
  setShippingCost,
  setTollCost,
  setShippingDate,
  setWarehouse,
  setEstimatedHoursForShipping,
  setShippingDistance,
  setShippingTime,
  setUserAddresses,
  setUserBillingAddresses,
  setNewUserName,
  setNewUserLastName,
  setNewUserEmail,
  setNewUserPhone,
  clearAfterPurchase,
  setPreviousShippingCost,
  setPreviousTollCost,
} from "../../slices/checkoutSlice";

const scrollToRef = (ref) => {
  window.scrollTo(0, ref.current.offsetTop);
};

const newAddressMockup = {
  idUsuario: 0,
  identificador: "",
  latitud: 9.961160065823192,
  longitud: -84.25559856677866,
  urlWaze: "",
  direccionExacta: "",
  telefono: "",
  nombreCompleto: "",
  distrito: null,
  codigoPaisTelefono: "",
};

const newBillingMockup = {
  nombreFactura: "",
  tipoIdentificacion: 0,
  identificacion: "",
  correoFactura: "",
};

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function CheckoutPage({ cart, user, userIsLogged }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((store) => store.app.user);

  const [phoneUser, setPhoneUser] = useState("");

  const orderInfo = useRef(null);

  const query = useQuery();

  const codeRef = useRef(null);

  const whatsappReconfirmation = useSelector(
    (state) => state.checkout.whatsappReconfirmation
  );
  const giveawayAuthorization = useSelector(
    (state) => state.checkout.giveawayAuthorization
  );
  const acceptTermsAndConditions = useSelector(
    (state) => state.checkout.acceptTermsAndConditions
  );
  const printBill = useSelector((state) => state.checkout.printBill);

  const addressDetails = useSelector((state) => state.checkout.addressDetails);
  const selectedStore = useSelector((state) => state.checkout.selectedStore);
  const billing = useSelector((state) => state.checkout.billing);
  const billingDetails = useSelector((state) => state.checkout.billingDetails);
  const paymentMethod = useSelector((state) => state.checkout.paymentMethod);
  const stores = useSelector((state) => state.checkout.stores);
  const paymentMethods = useSelector((state) => state.checkout.paymentMethods);
  const cashAmount = useSelector((state) => state.checkout.cashAmount);
  const orderNotes = useSelector((state) => state.checkout.orderNotes);
  const shippingMethods = useSelector(
    (state) => state.checkout.shippingMethods
  );
  const shippingMethod = useSelector((state) => state.checkout.shippingMethod);
  const shippingCost = useSelector((state) => state.checkout.shippingCost);
  const previousShippingCost = useSelector(
    (state) => state.checkout.previousShippingCost
  );
  const previousTollCost = useSelector(
    (state) => state.checkout.previousTollCost
  );

  const tollCost = useSelector((state) => state.checkout.tollCost);
  const shippingDate = useSelector((state) => state.checkout.shippingDate);
  const estimatedHoursForShipping = useSelector(
    (state) => state.checkout.estimatedHoursForShipping
  );
  const shippingDistance = useSelector(
    (state) => state.checkout.shippingDistance
  );
  const shippingTime = useSelector((state) => state.checkout.shippingTime);
  const warehouse = useSelector((state) => state.checkout.warehouse);
  const userAddresses = useSelector((state) => state.checkout.userAddresses);
  const userBillingAddresses = useSelector(
    (state) => state.checkout.userBillingAddresses
  );
  const newUserName = useSelector((state) => state.checkout.newUserName);
  const newUserLastName = useSelector(
    (state) => state.checkout.newUserLastName
  );
  const newUserEmail = useSelector((state) => state.checkout.newUserEmail);
  const newUserPhone = useSelector((state) => state.checkout.newUserPhone);

  const { roles } = useSelector((state) => state.app.user);
  const { products } = useSelector((state) => state.app.cart);

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showTermsAndConditionsGiveaway, setShowTermsAndConditionsGiveaway] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [activeSection, setActiveSection] = useState(null);
  const [displayLogin, setDisplayLogin] = useState(null);
  const [petName, setPetName] = useState(null);
  const [showReorderMessage, setShowReorderMessage] = useState(false);

  const [preCompleted, setPreCompleted] = useState(null);
  const [MsgAlert, setMsgAlert] = useState(null);
  const [showTime, setShowTime] = useState(null);

  const [differedTransportCost, setDifferedTransportCost] = useState(null);

  // Transporte gratis
  const [freeShipping, setFreeShipping] = useState(false);

  const toogleShipping = (value) => {
    setFreeShipping(value);
    dispatch(setShippingCost(value ? null : previousShippingCost));
    dispatch(setTollCost(value ? null : previousTollCost));
  };

  // Precio Descuento por producto
  const [discountPrice, setDiscountPrice] = useState({});
  const [subTotalWhitDiscountPrice, setSubTotalWhitDiscountPrice] =
    useState(null);

  useEffect(() => {
    let sumDiscount = 0;
    let subTotal = cart.total;

    if (products && discountPrice) {
      if (paymentMethod?.esEnEfectivo) {
        subTotal =
          cart.totalWithEfectiveDiscount > 0
            ? cart.totalWithEfectiveDiscount
            : subTotal;
      } else {
        subTotal =
          cart.totalWithAnyDiscount > 0 ? cart.totalWithAnyDiscount : subTotal;
      }
    }

    if (discountPrice) {
      //Transformar el objeto en un array con solo los precios

      const discountPriceArray = Object.values(discountPrice);
      //Sumar todos los descuentos
      discountPriceArray.forEach((price) => {
        if (price) {
          sumDiscount += price.precioConDescuento * price.quantity;
        }
      });
      //Restar el descuento al total
    }

    subTotal -= sumDiscount;

    setSubTotalWhitDiscountPrice(subTotal);
  }, [discountPrice, products, cart, paymentMethod]);

  const handleDebounceFn = async (
    inputValue,
    productId,
    directDiscount = false
  ) => {
    if (directDiscount) {
      if (HILL_PAWS.includes(Number(productId)) && inputValue === "022024") {
        const totalPrice = await singleFetch(
          `producto-detalles/${productId}/descuento/10`
        );

        const price = await totalPrice.json();

        setDiscountPrice((currentDiscountPrice) => ({
          ...currentDiscountPrice,
          [productId]: {
            ...price,
            precioConDescuento: price.precioConDescuento,
            quantity: Math.min(3, products[productId].quantity),
          },
        }));
      }
    } else {
      if (inputValue.length >= 1 && inputValue.length <= 2) {
        const totalPrice = await singleFetch(
          `producto-detalles/${productId}/descuento/${Number(inputValue)}`
        );

        const price = await totalPrice.json();

        setDiscountPrice((currentDiscountPrice) => ({
          ...currentDiscountPrice,
          [productId]: { ...price, quantity: products[productId].quantity },
        }));
      } else {
        setDiscountPrice((currentDiscountPrice) => ({
          ...currentDiscountPrice,
          [productId]: null,
        }));
      }
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

  const handleChange = (e, productId, directDiscount = false) => {
    //validar que solo se puedan ingresar numeros mayores a 0
    if (!directDiscount) {
      if (Number(e.target.value) < 0 || isNaN(Number(e.target.value))) {
        return;
      }
    }
    debounceFn(e.target.value, productId, directDiscount);
  };

  // Pedido inicial de todas las tiendas de ticolitas
  const fetchStores = useCallback(async () => {
    const storesFetch = await singleFetch(
      'tienda-ticolitas?filter={"order":"nombre"}'
    );
    const storesData = await storesFetch.json();
    dispatch(setStores(storesData));
  }, [dispatch]);

  // Pedido inicial de todos los metodos de entrega de ticolitas
  const fetchShippingMethods = useCallback(async () => {
    let filter = {
      order: "nombre",
      where: {
        and: [
          { activo: 1 },
          {
            or: [
              { idOrganizacion: null },
              user && user.token && user.idOrganizacion
                ? { idOrganizacion: user.idOrganizacion }
                : {},
            ],
          },
        ],
      },
    };

    const shippingMethodsFetch = await singleFetch(
      `metodo-entrega?filter=${JSON.stringify(filter)}`
    );
    const shippingMethodsData = await shippingMethodsFetch.json();
    dispatch(setShippingMethods(shippingMethodsData));
  }, [dispatch, user]);

  // Pedido inicial de todos los metodos de pagos de ticolitas
  const fetchPaymentMethods = useCallback(async () => {
    const fetchMethods = await singleFetch(
      `metodos-pago?filter={"order":"nombre","where":{"and":[{"activo":1},{"or":[{"idOrganizacion":null}${
        user && user.token && user.idOrganizacion
          ? `,{"idOrganizacion":${user.idOrganizacion}}`
          : ""
      }]}]}}`
    );
    const data = await fetchMethods.json();
    dispatch(setPaymentMethods(data));
  }, [dispatch, user]);

  // Se muestran mensajes si se utiliza el boton de volver a comprar y si se indica el nombre de la mascota
  useEffect(() => {
    const mascotas = query.get("mascotas");
    const reorder = query.get("reorder");
    if (mascotas) {
      setPetName(mascotas);
      setTimeout(() => {
        setPetName(null);
      }, 5000);
    }
    if (reorder) {
      setShowReorderMessage(true);
      setTimeout(() => {
        setShowReorderMessage(false);
      }, 5000);
    }
  }, [query]);

  // Se carga la informacion de nuevo en caso de que el usuario cambien (cierre sesion o inicie sesion)
  useEffect(() => {
    if (user && user.token) {
      fetchStores();
      fetchPaymentMethods();
      fetchShippingMethods();
    }
  }, [fetchPaymentMethods, fetchShippingMethods, fetchStores, user]);

  // Se cargan configuraciones del usuarios (direcciones y datos de facturacion)
  const fetchUserData = useCallback(async () => {
    const userAddressesFetch = await authFetch(
      `usuario-direcciones?filter={"where":{"idUsuario":${user.id}}}`,
      null,
      "GET",
      user.token,
      dispatch
    );
    const userAddressesData = await userAddressesFetch.json();
    userAddressesData.push({
      id: "newAddress",
      identificador: "+ Nueva",
    });
    dispatch(setUserAddresses(userAddressesData));

    const userBillingFetch = await authFetch(
      `usuario-facturacion?filter={"where":{"idUsuario":${user.id}}}`,
      null,
      "GET",
      user.token,
      dispatch
    );
    const userBillingData = await userBillingFetch.json();
    //sort userBillingData by nombreFactura

    userBillingData.sort((a, b) => {
      if (a.nombreFactura.toLowerCase() > b.nombreFactura.toLowerCase()) {
        return 1;
      }
      if (a.nombreFactura.toLowerCase() < b.nombreFactura.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    userBillingData.splice(0, 0, {
      nombreFactura: "+ Ingresar nuevos datos",
      id: "newBilling",
    });
    userBillingData.splice(0, 0, {
      nombreFactura: "No deseo factura electrónica",
      id: "noBilling",
    });
    dispatch(setUserBillingAddresses(userBillingData));
  }, [dispatch, user.id, user.token]);

  // Efecto que manda a pedir las configuraciones del usuario
  useEffect(() => {
    if (userIsLogged) {
      fetchUserData();
    }
  }, [fetchUserData, userIsLogged]);

  // Modal de terminos y condiciones
  const closeTermsAndConditionsModal = () => {
    dispatch(setShowTermsAndConditions(false));
  };

  // Modal de condiciones de promocion
  const closeTermsAndConditionsGiveawayModal = () => {
    dispatch(setShowTermsAndConditionsGiveaway(false));
  };

  //based on a distance in meters calculate the cost of that shipping
  const calcShippingCost = useCallback(
    (distance) => {
      if (
        shippingMethod &&
        cart.productsCount > 0 &&
        cart.total < shippingMethod.gratisDespuesDe
      ) {
        const shippingCostCalculation =
          shippingMethod.costoBase +
          Math.ceil(distance / 1000) * shippingMethod.costoPorKm;
        return shippingCostCalculation;
      } else {
        return 0;
      }
    },
    [cart.productsCount, cart.total, shippingMethod]
  );

  // Calcula la distancia con el punto de entrega
  // Actualmente se utiliza para cuando el usuario comparte su dirección porque se encuentra en el punto de entrega
  // o cuando el usuario tiene una dirección pre cargada que ya tiene la latitud y la longitud especificada
  const fetchDistance = useCallback(
    async (lat, lng) => {
      try {
        if (warehouse) {
          const distanceFetch = await authFetch(
            "distancia",
            {
              origin: {
                lat: warehouse.latitud,
                lng: warehouse.longitud,
              },
              destination: { lat, lng },
            },
            "POST",
            token
          );
          const distanceData = await distanceFetch.json();
          const directionsData = distanceData.routes[0].legs[0]; // Get data about the mapped route
          if (!directionsData) {
            return;
          } else {
            if (directionsData) {
              dispatch(setShippingDistance(directionsData.distance.value));
              dispatch(setShippingTime(directionsData.duration.value));
              if (shippingMethod && shippingMethod.requiereDireccion) {
                dispatch(
                  setShippingCost(
                    calcShippingCost(directionsData.distance.value)
                  )
                );
                dispatch(
                  setPreviousShippingCost(
                    calcShippingCost(directionsData.distance.value)
                  )
                );
              }
            }
          }
        }
      } catch (error) {}
    },
    [calcShippingCost, dispatch, shippingMethod, warehouse, token]
  );

  useEffect(() => {
    if (addressDetails && warehouse) {
      const lat = addressDetails.latitud || addressDetails.distrito.latitud;
      const lng = addressDetails.longitud || addressDetails.distrito.longitud;
      if (
        lat &&
        lng &&
        warehouse.latitud &&
        warehouse.longitud &&
        shippingMethod
      ) {
        fetchDistance(lat, lng);
      }
    }
  }, [addressDetails, fetchDistance, shippingMethod, warehouse]);

  const fetchEstimatedShippingDate = useCallback(async () => {
    const selectedShippingMethod = shippingMethod && shippingMethod.id;
    const lineas = [];
    for (const p in products) {
      lineas.push({
        idProducto: products[p].product.id,
        cantidad: products[p].quantity,
      });
    }

    const estimatedDatePayload = {
      fecha: new Date(),
      idMetodoEntrega: selectedShippingMethod,
      lineas,
    };

    if (selectedShippingMethod === METODOS_ENTREGA.PICKUP) {
      if (warehouse) {
        estimatedDatePayload.idBodega = warehouse.id;
      }
    } else {
      if (selectedShippingMethod === METODOS_ENTREGA.A_DOMICILIO) {
        if (addressDetails && addressDetails.distrito) {
          estimatedDatePayload.idProvincia =
            addressDetails.distrito.idProvincia;
          estimatedDatePayload.idCanton = addressDetails.distrito.idCanton;
          estimatedDatePayload.idDistrito = addressDetails.distrito.idDistrito;
        }
      }
    }
    const estimatedDateFetch = await authFetch(
      "ventas/fecha-estimada-envio",
      estimatedDatePayload,
      "POST",
      token
    );

    const estimatedDateData = await estimatedDateFetch.json();

    if (
      estimatedDateData.bodega &&
      (!warehouse || estimatedDateData.bodega.id !== warehouse.id)
    ) {
      dispatch(setWarehouse(estimatedDateData.bodega));
    }

    dispatch(setShippingDate(new Date(estimatedDateData.fechaEstimada)));
    dispatch(
      setEstimatedHoursForShipping(
        estimatedDateData.limite
          ? `entre ${estimatedDateData.horas} y ${
              estimatedDateData.horas + 24
            } horas`
          : `en menos de ${estimatedDateData.horas} horas`
      )
    );

    if (selectedShippingMethod !== METODOS_ENTREGA.LOCAL) {
      setShowTime(true);
    }
  }, [addressDetails, dispatch, products, shippingMethod, warehouse, token]);

  useEffect(() => {
    if (
      shippingMethod &&
      shippingMethod.id &&
      ((warehouse && warehouse.id) ||
        (addressDetails &&
          addressDetails.distrito &&
          addressDetails.distrito.idDistrito)) &&
      Object.keys(products).length > 0
    ) {
      fetchEstimatedShippingDate();
    }
  }, [
    addressDetails,
    fetchEstimatedShippingDate,
    products,
    shippingMethod,
    warehouse,
  ]);

  // scrollear al inicio cuando se carga la pantalla
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // generar lineas de orden para crear la venta
  const generateOrderLines = () => {
    const orderLines = [];
    let lineCounter = 1;
    for (const product in cart.products) {
      const productLine = cart.products[product];
      const taxes = 1.13;
      const unitPriceWithoutTaxes =
        (productLine.product.precioVenta * 100) / 113;
      const subtotalWhitoutDiscount =
        unitPriceWithoutTaxes * productLine.quantity;
      const productDiscount = productLine?.product?.descuentos
        ? productLine.product.descuentos[0]
        : null;
      const manualDiscount = discountPrice[productLine.product.id];

      let discount = 0;

      if (productDiscount) {
        if (
          !productDiscount.soloEnEfectivo ||
          (productDiscount.soloEnEfectivo && paymentMethod.esEnEfectivo)
        ) {
          discount =
            unitPriceWithoutTaxes *
            productDiscount.porcentaje *
            productLine.quantity;
        }
      } else {
        if (manualDiscount) {
          discount =
            productLine.product.precioVenta * productLine.quantity -
            manualDiscount.precioConDescuento * productLine.quantity;
        }
      }

      const subtotalWithDiscount = subtotalWhitoutDiscount - discount;

      orderLines.push({
        linea: lineCounter++,
        idProducto: productLine.product.id,
        cantidad: productLine.quantity,
        consumoDiarioGramos: productLine.dailyConsumption * 100,
        porcentajeImpuesto: taxes,
        costoUnitario: productLine.product.precioVenta,
        subtotal: subtotalWithDiscount,
        totalImpuestos: subtotalWithDiscount * (taxes - 1),
        total: subtotalWhitoutDiscount * taxes,
        descuento: discount,
        totalConDescuento: subtotalWithDiscount * taxes,
        mascotas: productLine.petName || "",
        unidadMedida: UNIDAD_MEDIDA_UNIDAD,
      });
    }
    return orderLines;
  };

  //metodo de creacion de orden
  const placeOrder = async () => {
    setLoading(true);
    const tempErrors = [];

    if (!user || !user.token) {
      tempErrors.push({
        message:
          'No has iniciado sesión. Si no tenés usuario podés crear tu usuario en esta misma pantalla en un solo paso y en segundos en la sección de "Cliente".',
        inputId: "noUser",
      });
      setErrors(tempErrors);
      setLoading(false);
      return;
    }

    if (!shippingMethod) {
      tempErrors.push({
        message: "No has seleccionado un metodo de entrega",
        inputId: "shippinMethodSelection",
      });
    } else {
      if (shippingMethod.requiereDireccion) {
        if (addressDetails) {
          const fieldsToCheck = {
            distrito: "distrito",
            direccionExacta: "señas adicionales para la entrega",
            telefono: "teléfono",
            nombreCompleto: "nombre completo de la persona que recibe",
            identificador:
              "nombre de referencia de la dirección para futuras compras",
            codigoPaisTelefono: "código teléfono de país",
          };
          for (const field in fieldsToCheck) {
            if (!addressDetails[field]) {
              tempErrors.push({
                message: `Por favor completá el campo ${fieldsToCheck[field]} en la sección de "Entrega"`,
                inputId: field,
              });
            }
          }
        } else {
          tempErrors.push({
            message:
              "No has seleccionado una dirección para tu entrega a domicilio",
            inputId: "addressSelection",
          });
        }
      } else {
        if (shippingMethod.requiereSeleccionTienda && !selectedStore) {
          tempErrors.push({
            message:
              "No has seleccionado una tienda donde se realizará la compra",
            inputId: "storeSelection",
          });
        }
      }
    }

    if (billing) {
      const fieldsToCheck = {
        nombreFactura: "nombre",
        tipoIdentificacion: "tipo de identificación",
        identificacion: "identificación",
        correoFactura: "correo electrónico",
      };
      for (const field in fieldsToCheck) {
        if (!billingDetails[field]) {
          tempErrors.push({
            message: `Por favor completá el campo ${fieldsToCheck[field]} en la sección de "Facturación"`,
            inputId: field,
          });
        }
      }
    }

    if (!paymentMethod) {
      tempErrors.push({
        message: "No has seleccionado un metodo de pago",
        inputId: "paymentMethodSelection",
      });
    } else {
      if (paymentMethod.requiereIndicarMonto && !cashAmount) {
        tempErrors.push({
          message:
            "No has ingresado el monto en efectivo con el que vas a cancelar.",
          inputId: "paymentMethodSelection",
        });
      }
    }

    if (!acceptTermsAndConditions && !shippingMethod.esLocal) {
      tempErrors.push({
        message:
          "No has aceptado los términos y condiciones de compra de TiColitas",
        inputId: "termsAndConditions",
      });
    }

    if (tempErrors.length > 0) {
      setLoading(false);
      setErrors(tempErrors);
      return;
    }
    setErrors([]);
    const orderPayload = {
      venta: {
        idUsuario: shippingMethod.esLocal ? USUARIO_TICOLITAS : user.id,
        idEstadoVenta:
          paymentMethod.estadoInicialOrden !==
          shippingMethod.estadoOrdenAlIngresar
            ? paymentMethod.idOrganizacion !== null
              ? paymentMethod.estadoInicialOrden
              : shippingMethod.estadoOrdenAlIngresar
            : paymentMethod.estadoInicialOrden,
        idOrganizacionMetodoPago: paymentMethod.idOrganizacion,
        idOrganizacionMetodoEntrega: shippingMethod.idOrganizacion,
        idUsuarioEnOrganizacion: user.idEnOrganizacion,
        realizada: true,
        totalDeTransporte: differedTransportCost
          ? differedTransportCost
          : (shippingCost || 0) + (tollCost || 0),
        totalConImpuestos: subTotalWhitDiscountPrice
          ? subTotalWhitDiscountPrice
          : cart.total,
        descuentoGlobal: cart.discount || 0,
        totalCancelado: null,
        fechaIngreso: new Date(),
        fechaMaximaPago: new Date(),
        fechaRealPago: shippingMethod.esLocal ? new Date() : null,
        fechaEntregaInicio:
          !shippingMethod.requiereDireccion === 1 ? new Date() : null,
        fechaEntregaEstimada: shippingDate,
        fechaEntregaFinal: shippingMethod.esLocal === 1 ? new Date() : null,
        nombreCompletoEntrega: shippingMethod.requiereDireccion
          ? addressDetails.nombreCompleto
          : null,
        latitud: shippingMethod.requiereDireccion
          ? addressDetails.latitud
          : null,
        longitud: shippingMethod.requiereDireccion
          ? addressDetails.longitud
          : null,
        urlWaze: shippingMethod.requiereDireccion
          ? addressDetails.urlWaze
          : null,
        provincia: shippingMethod.requiereDireccion
          ? addressDetails.distrito.idProvincia
          : null,
        canton: shippingMethod.requiereDireccion
          ? addressDetails.distrito.idCanton
          : null,
        distrito: shippingMethod.requiereDireccion
          ? addressDetails.distrito.idDistrito
          : null,
        direccionExacta: shippingMethod.requiereDireccion
          ? addressDetails.direccionExacta
          : null,
        notasEntrega: `${orderNotes} ${
          codeRef.current.value === "022024"
            ? "Código de descuento: 022024"
            : ""
        }`,
        telefono: shippingMethod.requiereDireccion
          ? parseInt(addressDetails.telefono)
          : null,
        codigoPaisTelefono:
          shippingMethod.requiereDireccion &&
          addressDetails &&
          addressDetails.codigoPaisTelefono
            ? addressDetails.codigoPaisTelefono
            : 506,
        nombreFactura: billing ? billingDetails.nombreFactura : null,
        tipoIdentificacionFactura: billing
          ? billingDetails.tipoIdentificacion
          : null,
        identificacionFactura: billing ? billingDetails.identificacion : null,
        correoFactura: billing ? billingDetails.correoFactura : null,
        autorizaContactoWhatsapp: cart.whatsappConfirmation,
        confirmaAutorizaContactoWhatsapp: whatsappReconfirmation,
        aceptaTerminosCondiciones: acceptTermsAndConditions,
        facturaImpresa: printBill,
        totalConTransporte: subTotalWhitDiscountPrice
          ? subTotalWhitDiscountPrice +
            (differedTransportCost
              ? differedTransportCost
              : (shippingCost || 0) + (tollCost || 0))
          : cart.total +
            (differedTransportCost
              ? differedTransportCost
              : (shippingCost || 0) + (tollCost || 0)),

        idMetodoPago: paymentMethod.id,
        metodoPago: paymentMethod.nombre,
        pagoRequiereFirma: subTotalWhitDiscountPrice
          ? subTotalWhitDiscountPrice +
              ((shippingCost || 0) + (tollCost || 0)) >
              paymentMethod.montoMinimoParaFirma && paymentMethod.requierePos
            ? true
            : false
          : cart.total + ((shippingCost || 0) + (tollCost || 0)) >
              paymentMethod.montoMinimoParaFirma && paymentMethod.requierePos
          ? true
          : false,
        requierePos: paymentMethod.requierePos,
        indicacionesPostCompra: paymentMethod.indicacionesPostCompra,
        idMetodoEntrega: shippingMethod.id,
        metodoEntrega: shippingMethod.nombre,
        distanciaTiendaMetros: shippingDistance,
        duracionDesdeTiendaMinutos: shippingTime,
        montoEfectivo: cashAmount,
        idTiendaPickup:
          shippingMethod.esLocal ||
          (!shippingMethod.esLocal && !shippingMethod.requiereDireccion)
            ? selectedStore
            : null,
        idBodega: warehouse.id,
        idTransportista: null,
        posicionEnEntrega: DEFAULT_POSITION_IN_SHIPPING_ORDER,
        autorizacionPromocion: giveawayAuthorization,
        idOrigenCompra: COMPRA_EN_LINEA,
      },
      lineas: generateOrderLines(),
      incluyeAccesorios:
        Object.keys(cart.products).find(
          (product) => cart.products[product].product.especie === "Accesorios"
        ) !== undefined,
    };

    if (
      newUserName &&
      newUserEmail &&
      shippingMethod.esLocal &&
      acceptTermsAndConditions
    ) {
      orderPayload.nuevoUsuario = {
        nombre: newUserName,
        apellido1: newUserLastName || null,
        email: newUserEmail,
        telefono: newUserPhone || null,
      };
    }

    if (shippingMethod.requiereDireccion && !addressDetails.id) {
      orderPayload.nuevaDireccion = {
        ...addressDetails,
        codigoPaisTelefono: addressDetails.codigoPaisTelefono,
        telefono: parseInt(addressDetails.telefono),
        provincia: addressDetails.distrito.idProvincia,
        canton: addressDetails.distrito.idCanton,
        distrito: addressDetails.distrito.idDistrito,
      };
    }

    if (billing && !billingDetails.id) {
      orderPayload.nuevaFacturacion = billingDetails;
    }

    const orderCreationFetch = await authFetch(
      "ventas",
      orderPayload,
      "POST",
      user.token
    );

    if (orderCreationFetch.status === 200) {
      const orderCreated = await orderCreationFetch.json();

      if (printBill && shippingMethod.esLocal) {
        const orderForPrint = orderPayload.venta;
        orderForPrint.id = orderCreated.id;
        orderForPrint.lineas = orderPayload.lineas;
        orderForPrint.lineas.forEach((linea) => {
          linea.producto = cart.products[linea.idProducto].product;
        });
        const payload = {
          order: orderForPrint,
          vendorId: 4070,
          productId: 33054,
        };
        setLoading(true);
        fetch(warehouse.urlServidorDeImpresion, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(payload),
        });
      }
      dispatch(clearCart());
      dispatch(clearAfterPurchase());
      navigate(`/pedidos/${orderCreated.id}?desde=checkout`);
    } else {
      try {
        const error = await orderCreationFetch.json();
        const message = error && error.error && error.error.message;
        if (message) {
          dispatch(
            setErrors([
              {
                message,
              },
            ])
          );
        } else {
          throw new Error();
        }
      } catch (error) {
        setErrors([
          {
            message:
              "Lo sentimos, ha ocurrido un error creando tu orden. Intentálo de nuevo, si el error persiste podés escribirnos para asistirte.",
          },
        ]);
      }
    }
    setLoading(false);
  };

  const [locations, setLocations] = useState([]); //array de distritos
  const [errorPhone, setErrorPhone] = useState(false);

  useEffect(() => {
    const fetchLocations = async () => {
      const locationsFetch = await singleFetch("provincia-canton-distrito");
      const locationsData = await locationsFetch.json();
      setLocations(locationsData);
    };
    fetchLocations();
  }, []);

  const setDistrito = useCallback(
    (idCanton, idDistrito, idProvincia) => {
      const value = locations.find(
        (l) =>
          l.idProvincia === idProvincia &&
          l.idCanton === idCanton &&
          l.idDistrito === idDistrito
      );
      return value;
    },
    [locations]
  ); // metodo que encuentra un distrito dentro de la lista de distritos con base en su idProvincia, idCanton y idDistrito

  // search user date base on phone number
  const searchByPhone = async () => {
    if (phoneUser) {
      setLoading(true);
      const request = await singleFetch(
        `ventas/busqueda-venta-por-telefonos/${phoneUser}`
      );

      if (request.status === 200) {
        const resp = await request.json();
        setPreCompleted(false);
        const loadedDistrict = setDistrito(
          resp.canton,
          resp.distrito,
          resp.provincia
        );
        dispatch(
          setAddressDetails({
            ...addressDetails,
            direccionExacta: resp.direccionExacta,
            codigoPaisTelefono: resp.codigoPaisTelefono,
            telefono: resp.telefono,
            nombreCompleto: resp.nombreCompletoEntrega,
            distrito: loadedDistrict,
            latitud: resp.latitud,
            longitud: resp.longitud,
            urlWaze: resp.urlWaze
              ? resp.urlWaze
              : resp.latitud && resp.longitud
              ? `https://www.waze.com/ul?ll=${resp.latitud}%2C${resp.longitud}&navigate=yes&zoom=17`
              : null,
            identificador: "Pre-cargada",
          })
        );

        dispatch(setTollCost(loadedDistrict.montoPeaje));
        dispatch(setPreviousTollCost(loadedDistrict.montoPeaje));
        dispatch(
          setShippingMethod(
            shippingMethods.find((sm) => {
              return sm.id === METODOS_ENTREGA.A_DOMICILIO;
            })
          )
        );

        dispatch(
          setPaymentMethod(
            paymentMethods.find((pm) => pm.id === resp.idMetodoPago)
          )
        );

        if (resp.tipoIdentificacionFactura) {
          dispatch(
            setBillingDetails({
              ...billingDetails,
              nombreFactura: resp.nombreFactura,
              tipoIdentificacion: resp.tipoIdentificacionFactura,
              identificacion: resp.identificacionFactura,
              correoFactura: resp.correoFactura,
            })
          );
          dispatch(setBilling(true));
        } else {
          dispatch(setBillingDetails(null));
          dispatch(setBilling(false));
        }

        setErrorPhone(false);
        setMsgAlert("Datos precargados correctamente");
      }

      if (
        request.status === 404 ||
        request.status === 400 ||
        request.status === 500
      ) {
        setPreCompleted(false);
        setErrorPhone(true);
        setMsgAlert("Por favor verifique el número de teléfono ingresado.");
      }
      setLoading(false);
    }
  };

  // Effect to calculate shipping cost if shipping distance or shipping method changed
  useEffect(() => {
    if (
      shippingMethod &&
      shippingMethod.requiereDireccion &&
      shippingDistance
    ) {
      dispatch(setShippingCost(calcShippingCost(shippingDistance)));
      dispatch(setPreviousShippingCost(calcShippingCost(shippingDistance)));
    }
  }, [calcShippingCost, dispatch, shippingDistance, shippingMethod]);

  return (
    <div className="flex flex-wrap w-full p-content py-8 gap-2">
      {loading && <Spinner color="pink" />}
      <Modal
        backgroundColor="bg-purple"
        closeModalFunction={closeTermsAndConditionsModal}
        open={showTermsAndConditions}
      >
        <TermsAndConditions />
      </Modal>
      <Modal
        backgroundColor="bg-purple"
        closeModalFunction={closeTermsAndConditionsGiveawayModal}
        open={showTermsAndConditionsGiveaway}
      >
        <TermsAndConditionsGiveaway />
      </Modal>
      {petName && (
        <div className="bg-cyan text-white p-8 w-full">
          <p>
            Hemos agregado el alimento de{" "}
            <span className="font-bold">{petName}</span> a tu tacita de compras.
          </p>
        </div>
      )}
      {showReorderMessage && (
        <div className="bg-cyan text-white p-8 w-full">
          <p>
            Hemos agregado a tu orden actual los productos de tu orden
            seleccionada con base en la disponibilidad de nuestro inventario.
          </p>
        </div>
      )}
      <Cart
        cart={cart}
        loading={loading}
        checkout
        district={addressDetails && addressDetails.distrito}
        shippingCost={shippingCost}
        tollCost={tollCost}
        shippingDate={shippingDate}
        estimatedHoursForShipping={estimatedHoursForShipping}
        shippingInAssociationOffice={
          shippingMethod && shippingMethod.idOrganizacion
        }
        isPickup={
          shippingMethod &&
          !shippingMethod.esLocal &&
          !shippingMethod.requiereDireccion
        }
        isLocalDelivery={shippingMethod && shippingMethod.esLocal}
        hideButtons
        additionalStyles="flex w-full"
        showTime={showTime}
        freeShipping={freeShipping}
        toogleShipping={toogleShipping}
        handleChange={handleChange}
        discountPrice={discountPrice}
        subTotalWhitDiscountPrice={subTotalWhitDiscountPrice}
        differedTransportCost={differedTransportCost}
        setDifferedTransportCost={setDifferedTransportCost}
        codeRef={codeRef}
      />
      {cart.productsCount > 0 ? (
        <>
          <div className="flex flex-wrap w-full bg-purple p-8">
            <div ref={orderInfo} className="w-full">
              <p className="text-white text-xl mb-6">Información del pedido</p>
            </div>
            <div
              onClick={() => {
                setActiveSection(activeSection === 0 ? null : 0);
                scrollToRef(orderInfo);
              }}
              className={`${
                activeSection === 0 ? "bg-orange" : "bg-white"
              } p-6 w-full mb-2 cursor-pointer flex justify-between items-center`}
            >
              <p className={activeSection === 0 ? "text-white" : "text-base"}>
                Cliente
              </p>
              {activeSection !== 0 && (
                <p
                  className={`rotate-90 text-2xl ${
                    activeSection === 0 ? "text-white" : "text-base"
                  }`}
                >
                  {">"}
                </p>
              )}
              {activeSection === 0 && (
                <p
                  className={`rotate-90 text-2xl ${
                    activeSection === 0 ? "text-white" : "text-base"
                  }`}
                >
                  {"<"}
                </p>
              )}
            </div>
            {activeSection === 0 && (
              <div className="bg-white p-6 w-full mb-2">
                {user && user.token ? (
                  <>
                    <p className="mb-4">Hola, {user.nombre}</p>
                    <p className="">
                      Hemos cargado tus preferencias, podés continuar con la
                      compra.
                    </p>
                    <br />

                    {roles.includes("administradorTienda") && (
                      <div>
                        <p className="">
                          Si desea pre llenar el formulario con los datos de un
                          cliente, digite el número de teléfono del cliente.
                        </p>
                        <input
                          id="phoneUser"
                          name="phoneUser"
                          className="appearance-none w-full outline-none rounded-3xl my-2 h-12 p-6 border border-purple"
                          onChange={(evt) => {
                            setErrorPhone(false);
                            setMsgAlert("");
                            setPhoneUser(evt.target.value);
                          }}
                          placeholder="88230225"
                          value={phoneUser}
                        />
                        <input
                          className="bg-cyan cursor-pointer text-white py-2 px-8 my-3 font-bold rounded-full float-right flex transition-shadow duration-300 ease-in-out justify-center items-center hover:shadow-xl hover:shadow-cyan/30"
                          type="submit"
                          value={"Buscar"}
                          onClick={searchByPhone}
                        />

                        <p
                          className={`${
                            errorPhone ? "text-pink" : "text-cyan/95"
                          } text-lg`}
                        >
                          {MsgAlert}
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div>
                      <>
                        ¿Ya tenés cuenta?{" "}
                        <button
                          onClick={() => {
                            setDisplayLogin(true);
                          }}
                          className="bg-purple text-white h-12 px-8 rounded-full"
                        >
                          Iniciar sesión
                        </button>
                      </>
                      <hr className="my-4" />
                      <>
                        ¿No tenés cuenta?{" "}
                        <button
                          onClick={() => {
                            setDisplayLogin(false);
                          }}
                          className="bg-purple text-white h-12 px-8 rounded-full mb-4"
                        >
                          Regístrate
                        </button>
                      </>
                      {displayLogin === true && <LoginForm noSignUpButton />}
                      {displayLogin === false && <SignupForm />}
                    </div>
                  </>
                )}
              </div>
            )}
            <div
              onClick={() => {
                if (user && user.token) {
                  setActiveSection(activeSection === 1 ? null : 1);
                  scrollToRef(orderInfo);
                }
              }}
              className={`${
                user && user.token
                  ? `${
                      activeSection === 1 ? "bg-orange" : "bg-white"
                    }  cursor-pointer`
                  : "bg-cat cursor-not-allowed"
              } p-6 w-full mb-2 flex justify-between items-center`}
            >
              <p className={activeSection === 1 ? "text-white" : "text-base"}>
                Entrega
              </p>
              {activeSection !== 1 && (
                <p
                  className={`rotate-90 text-2xl ${
                    activeSection === 1 ? "text-white" : "text-base"
                  }`}
                >
                  {">"}
                </p>
              )}
              {activeSection === 1 && (
                <p
                  className={`rotate-90 text-2xl ${
                    activeSection === 1 ? "text-white" : "text-base"
                  }`}
                >
                  {"<"}
                </p>
              )}
            </div>
            {activeSection === 1 && (
              <div className="bg-white p-6 w-full mb-2">
                <div className="w-full mt-4">
                  <Select
                    label="Seleccione el tipo de entrega:"
                    labelColor="text-base"
                    buttonClasses="border border-purple"
                    options={_.compact(
                      shippingMethods.map((sm) => {
                        if (
                          sm.esLocal &&
                          (!user ||
                            _.intersection(user.roles, sm.rolesAutorizados)
                              .length === 0)
                        ) {
                          return null;
                        }
                        return {
                          value: sm.id,
                          label: sm.nombre,
                        };
                      })
                    )}
                    onChange={(value) => {
                      const selectedShippingMethod = shippingMethods.find(
                        (sm) => sm.id === value
                      );
                      dispatch(setShippingMethod(selectedShippingMethod));
                      dispatch(setShippingDate(null));
                      dispatch(setEstimatedHoursForShipping(null));
                      dispatch(setSelectedStore(null));
                      dispatch(setShippingCost(null));
                      dispatch(setPreviousShippingCost(null));
                      dispatch(setShippingDistance(null));
                      dispatch(setShippingTime(null));
                      dispatch(setAddressDetails(null));
                      dispatch(setTollCost(null));
                      dispatch(setPreviousTollCost(null));
                      dispatch(setWarehouse(null));
                      setShowTime(false);
                    }}
                    value={shippingMethod && shippingMethod.id}
                  />
                </div>
                <div className="w-full mt-4">
                  <p>
                    {shippingMethod && shippingMethod.instruccionesPreCompra}
                  </p>
                </div>
                <div className="w-full mt-4">
                  {shippingMethod && shippingMethod.requiereDireccion === 1 && (
                    <>
                      <Select
                        label="Seleccione la dirección de entrega:"
                        labelColor="text-base"
                        buttonClasses="border border-purple"
                        options={userAddresses.map((sm) => {
                          return {
                            value: sm.id,
                            label: sm.identificador,
                          };
                        })}
                        onChange={(value) => {
                          if (value === "newAddress") {
                            dispatch(setAddressDetails(newAddressMockup));
                            return;
                          }
                          const selectedUserAddress = userAddresses.find(
                            (sm) => sm.id === value
                          );
                          dispatch(setAddressDetails(selectedUserAddress));
                        }}
                        value={
                          addressDetails
                            ? addressDetails.id || "newAddress"
                            : null
                        }
                      />
                      {addressDetails && (
                        <AddressForm
                          preCompleted={preCompleted}
                          forceSpecificDeliveryPoint={
                            cart.total < shippingMethod.gratisDespuesDe
                          }
                          address={addressDetails}
                          onChangeValue={(field, value, option) => {
                            if (field === "latitud") {
                              if (value === null) {
                                dispatch(
                                  setAddressDetails({
                                    ...addressDetails,
                                    latitud: null,
                                    longitud: null,
                                    urlWaze: null,
                                  })
                                );
                              } else {
                                dispatch(
                                  setAddressDetails({
                                    ...addressDetails,
                                    latitud: value.lat,
                                    longitud: value.lng,
                                    urlWaze: `https://www.waze.com/ul?ll=${value.lat}%2C${value.lng}&navigate=yes&zoom=17`,
                                  })
                                );
                              }
                            } else {
                              if (field === "distrito") {
                                if (value.montoPeaje && value.montoPeaje > 0) {
                                  dispatch(setTollCost(value.montoPeaje));
                                  dispatch(
                                    setPreviousTollCost(value.montoPeaje)
                                  );
                                } else {
                                  dispatch(setTollCost(null));
                                  dispatch(setPreviousTollCost(null));
                                }
                              }
                              dispatch(
                                setAddressDetails({
                                  ...addressDetails,
                                  [field]: value,
                                })
                              );
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                  {shippingMethod &&
                    shippingMethod.requiereSeleccionTienda === 1 && (
                      <Select
                        label="Por favor elegí una tienda:"
                        labelColor="text-base"
                        buttonClasses="border border-purple"
                        options={stores.map((s) => {
                          return {
                            label: s.nombre + " - " + s.horario,
                            value: s.id,
                          };
                        })}
                        onChange={(value) => {
                          const selectedWarehouse = stores.find(
                            (s) => s.id === value
                          );
                          dispatch(setSelectedStore(value));
                          dispatch(setWarehouse(selectedWarehouse));
                        }}
                        value={selectedStore}
                      />
                    )}
                  {shippingMethod && shippingMethod.esLocal === 1 && (
                    <>
                      <div className="w-full mt-4">
                        <CheckboxInput
                          checked={acceptTermsAndConditions}
                          onCheckChange={() => {
                            dispatch(
                              setAcceptTermsAndConditions(
                                !acceptTermsAndConditions
                              )
                            );
                          }}
                          labelColor="text-base"
                          label={"He leído y estoy de acuerdo con los "}
                          highlightedLabel={"términos y condiciones de la web."}
                          onClickHighlight={() => {
                            dispatch(setShowTermsAndConditions(true));
                          }}
                        />
                      </div>
                      {acceptTermsAndConditions && (
                        <>
                          <div className="w-full mt-4">
                            <label htmlFor="newUserName" className="text-base">
                              Ingresá el nombre del cliente:
                            </label>
                            <input
                              id="newUserName"
                              name="newUserName"
                              className="appearance-none w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                              onChange={(evt) => {
                                dispatch(setNewUserName(evt.target.value));
                              }}
                              value={newUserName}
                            />
                          </div>
                          <div className="w-full mt-4">
                            <label
                              htmlFor="newUserLastName"
                              className="text-base"
                            >
                              Ingresá el apellido del cliente:
                            </label>
                            <input
                              id="newUserLastName"
                              name="newUserLastName"
                              className="appearance-none w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                              onChange={(evt) => {
                                dispatch(setNewUserLastName(evt.target.value));
                              }}
                              value={newUserLastName}
                            />
                          </div>
                          <div className="w-full mt-4">
                            <label htmlFor="newUserEmail" className="text-base">
                              Ingresá el correo electrónico del cliente:
                            </label>
                            <input
                              id="newUserEmail"
                              name="newUserEmail"
                              className="appearance-none w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                              onChange={(evt) => {
                                dispatch(setNewUserEmail(evt.target.value));
                              }}
                              value={newUserEmail}
                            />
                          </div>
                          <div className="w-full mt-4">
                            <label
                              htmlFor="montoEfectivo"
                              className="text-base"
                            >
                              Ingresá el teléfono del cliente:
                            </label>
                            <input
                              id="montoEfectivo"
                              name="montoEfectivo"
                              className="appearance-none w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                              onChange={(evt) => {
                                dispatch(
                                  setNewUserPhone(
                                    evt.target.value.replace(/[^0-9]/g, "")
                                  )
                                );
                              }}
                              value={newUserPhone}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            <div
              onClick={() => {
                if (user && user.token) {
                  setActiveSection(activeSection === 2 ? null : 2);
                  scrollToRef(orderInfo);
                }
              }}
              className={`${
                user && user.token
                  ? `${
                      activeSection === 2 ? "bg-orange" : "bg-white"
                    }  cursor-pointer`
                  : "bg-cat cursor-not-allowed"
              } p-6 w-full mb-2 cursor-pointer flex justify-between items-center`}
            >
              <p className={activeSection === 2 ? "text-white" : "text-base"}>
                Facturación
              </p>
              {activeSection !== 2 && (
                <p
                  className={`rotate-90 text-2xl ${
                    activeSection === 2 ? "text-white" : "text-base"
                  }`}
                >
                  {">"}
                </p>
              )}
              {activeSection === 2 && (
                <p
                  className={`rotate-90 text-2xl ${
                    activeSection === 2 ? "text-white" : "text-base"
                  }`}
                >
                  {"<"}
                </p>
              )}
            </div>
            {activeSection === 2 && (
              <div className="bg-white p-6 w-full mb-2">
                <div className="w-full mt-4">
                  <Select
                    label="Datos de facturación:"
                    labelColor="text-base"
                    buttonClasses="border border-purple"
                    options={userBillingAddresses.map((b) => {
                      return {
                        value: b.id,
                        label: b.nombreFactura,
                      };
                    })}
                    onChange={(value) => {
                      if (value === "noBilling") {
                        dispatch(setBilling(false));
                        dispatch(setBillingDetails(newBillingMockup));
                        return;
                      }
                      if (value === "newBilling") {
                        dispatch(setBillingDetails(newBillingMockup));
                        dispatch(setBilling(true));
                        return;
                      }
                      const selectedBillingAddress = userBillingAddresses.find(
                        (sm) => sm.id === value
                      );
                      dispatch(setBillingDetails(selectedBillingAddress));
                      dispatch(setBilling(true));
                    }}
                    value={
                      (billingDetails && billingDetails.id) ||
                      (billing ? "newBilling" : "noBilling")
                    }
                  />
                </div>
                <div className="w-full mt-4">
                  {billing && billingDetails && (
                    <BillingForm
                      billing={billingDetails}
                      onChangeValue={(field, value) => {
                        dispatch(
                          setBillingDetails({
                            ...billingDetails,
                            [field]: value,
                          })
                        );
                      }}
                      onChangeAll={(value) => {
                        dispatch(setBillingDetails(value));
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            <div
              onClick={() => {
                if (user && user.token) {
                  setActiveSection(activeSection === 3 ? null : 3);
                  scrollToRef(orderInfo);
                }
              }}
              className={`${
                user && user.token
                  ? `${
                      activeSection === 3 ? "bg-orange" : "bg-white"
                    }  cursor-pointer`
                  : "bg-cat cursor-not-allowed"
              } p-6 w-full mb-2 cursor-pointer flex justify-between items-center`}
            >
              <p className={activeSection === 3 ? "text-white" : "text-base"}>
                Pago
              </p>
              {activeSection !== 3 && (
                <p
                  className={`rotate-90 text-2xl ${
                    activeSection === 3 ? "text-white" : "text-base"
                  }`}
                >
                  {">"}
                </p>
              )}
              {activeSection === 3 && (
                <p
                  className={`rotate-90 text-2xl ${
                    activeSection === 3 ? "text-white" : "text-base"
                  }`}
                >
                  {"<"}
                </p>
              )}
            </div>
            {activeSection === 3 && (
              <div className="bg-white p-6 w-full mb-2">
                <div className="w-full mt-4">
                  <Select
                    label="Método de pago:"
                    labelColor="text-base"
                    buttonClasses="border border-purple"
                    options={paymentMethods.map((m) => {
                      return {
                        value: m.id,
                        label: m.nombre,
                      };
                    })}
                    onChange={(value) => {
                      const selectedPaymentMethod = paymentMethods.find(
                        (pm) => pm.id === value
                      );
                      if (selectedPaymentMethod) {
                        dispatch(setPaymentMethod(selectedPaymentMethod));
                      }
                    }}
                    value={paymentMethod && paymentMethod.id}
                  />
                </div>
                {paymentMethod && (
                  <>
                    {paymentMethod.indicacionesPreCompra && (
                      <div className="w-full mt-4 whitespace-pre-line">
                        <p>{paymentMethod.indicacionesPreCompra}</p>
                      </div>
                    )}
                    {paymentMethod.requiereIndicarMonto !== 0 && (
                      <div className="w-full mt-4">
                        <label htmlFor="montoEfectivo" className="text-base">
                          Por favor indicá el monto en ₡ (colones) con el que
                          vas a cancelar en efectivo:
                        </label>
                        <input
                          id="montoEfectivo"
                          name="montoEfectivo"
                          className="appearance-none w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                          onChange={(evt) => {
                            dispatch(
                              setCashAmount(
                                evt.target.value.replace(/[^0-9]/g, "")
                              )
                            );
                          }}
                          placeholder={
                            "Ingrese el monto en efectivo con el que va a cancelar"
                          }
                          value={cashAmount === 0 ? "" : cashAmount}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <div className="w-full mt-4">
              <label htmlFor="notasAlPedido" className="text-white">
                Notas al pedido:
              </label>
              <textarea
                placeholder="Ej: persona con quien dejar el pedido, contactarte antes por el medio que indiques, medidas de seguridad que debemos respetar."
                id="notasAlPedido"
                name="notasAlPedido"
                className="w-full rounded-3xl mt-2 p-6"
                rows={3}
                onChange={(evt) => {
                  dispatch(setOrderNotes(evt.target.value));
                }}
              />
            </div>
            <div className="w-full mt-4">
              <CheckboxInput
                checked={printBill}
                onCheckChange={() => {
                  dispatch(setPrintBill(!printBill));
                }}
                labelColor="text-white"
                label={
                  "Por favor marcá esta casilla SOLO si requerís una factura impresa, estimulamos no marcarla y así colaboramos para evitar el uso del papel, si no la marcás te enviaremos una copia de la factura al correo que indicaste."
                }
              />
            </div>
            {shippingMethod && shippingMethod.esLocal === 0 && (
              <>
                <div className="w-full mt-4">
                  <CheckboxInput
                    checked={whatsappReconfirmation}
                    onCheckChange={() => {
                      dispatch(
                        setWhatsappReconfirmation(!whatsappReconfirmation)
                      );
                    }}
                    labelColor="text-white"
                    label={
                      "Con el fin de no ser intrusivos, queremos re-confirmar tu autorización para contactarte por WhatsApp en la(s) fecha(s) indicada(s) para coordinar tu nuevo pedido."
                    }
                  />
                </div>
                <div className="w-full mt-4">
                  <CheckboxInput
                    checked={acceptTermsAndConditions}
                    onCheckChange={() => {
                      dispatch(
                        setAcceptTermsAndConditions(!acceptTermsAndConditions)
                      );
                    }}
                    labelColor="text-white"
                    label={"He leído y estoy de acuerdo con los "}
                    highlightedLabel={"términos y condiciones de la web."}
                    onClickHighlight={() => {
                      dispatch(setShowTermsAndConditions(true));
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {errors && errors.length > 0 && (
            <div className="w-full my-4 border border-pink p-8">
              Es necesario verificar lo siguiente:
              {errors.map((e, index) => {
                return (
                  <p key={`error_${index}`} className="text-pink my-2 w-full">
                    {e.message}
                  </p>
                );
              })}
            </div>
          )}
          <Cart
            finalButton
            cart={cart}
            checkout
            loading={loading}
            checkoutErrors={errors && errors.length > 0}
            district={addressDetails && addressDetails.distrito}
            shippingCost={shippingCost}
            tollCost={tollCost}
            shippingDate={shippingDate}
            shippingInAssociationOffice={
              shippingMethod && shippingMethod.idOrganizacion
            }
            estimatedHoursForShipping={estimatedHoursForShipping}
            isPickup={
              shippingMethod &&
              !shippingMethod.esLocal &&
              !shippingMethod.requiereDireccion
            }
            isLocalDelivery={shippingMethod && shippingMethod.esLocal}
            hideButtons
            hideProducts
            additionalStyles="flex w-full"
            placeOrderMethod={placeOrder}
            giveawayAuthorization={giveawayAuthorization}
            setGiveawayAuthorization={null}
            setShowTermsAndConditionsGiveaway={
              setShowTermsAndConditionsGiveaway
            }
            showTime={showTime}
            freeShipping={freeShipping}
            toogleShipping={toogleShipping}
            handleChange={handleChange}
            discountPrice={discountPrice}
            subTotalWhitDiscountPrice={subTotalWhitDiscountPrice}
            differedTransportCost={differedTransportCost}
            setDifferedTransportCost={setDifferedTransportCost}
          />
        </>
      ) : (
        <div className="w-full mt-4">
          <p className="w-full">
            Aún no has agregado nada a tu tacita de compras. Te invitamos a
            visitar nuestra tienda y/o utilizar la barra superior para buscar
            los productos favoritos de tu mascota.
          </p>
          <div className="my-8 text-center">
            <Link to="/tienda">
              <button className="bg-purple text-white h-12 px-12 rounded-full">
                Navegar la tienda
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckoutPage;
