import React, { useEffect, useState, createContext, useCallback } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import CierreForm from "../../components/CierreForm";
import { authFetch } from "../../utils/apiRequest";
import { getCurrentDay } from "../../utils/formatDates";
import { Spinner } from "../../components/Spinner";
import CierreNotFound from "../../components/CierreNotFound";
import CierreDetail from "../../components/CierreDetail";
import { useSelector } from "react-redux";

export const CierreContext = createContext();

export default function CierresPage() {
  const [filter, setFilter] = useState("transportista");
  const [transportistas, setTransportistas] = useState([]);
  const [loadingTransportistas, setLoadingTransportistas] = useState(false);

  const [fechaValue, setFechaValue] = useState(getCurrentDay());
  const [transportistaValue, setTransportistaValue] = useState(1);
  const [tiendaValue, setTiendaValue] = useState(1);

  const { token } = useSelector((store) => store.app.user);

  const [cierre, setCierre] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoadingTransportistas(true);
    const allTransportista = await authFetch(
      `transportistas?filter={
        "where": {"activo": 1}
      }`,
      null,
      "GET",
      token
    );

    const transportistasData = await allTransportista.json();
    setTransportistas(transportistasData);
    setLoadingTransportistas(false);
  }, [token]);

  const actualizarCierre = async () => {
    if (filter === "transportista") {
      setLoading(true);
      const res = await authFetch(
        `cierres/transportista/${transportistaValue}/fecha/${fechaValue}`,
        null,
        "GET",
        token
      );

      const data = await res.json();
      setCierre(data);

      setLoading(false);
    } else if (filter === "tienda") {
      setLoading(true);
      const res = await authFetch(
        `cierres/tienda/${tiendaValue}/fecha/${fechaValue}`,
        null,
        "GET",
        token
      );

      const data = await res.json();
      setCierre(data);

      setLoading(false);
    } else {
      setLoading(true);
      const res = await authFetch(
        `cierres/fecha/${fechaValue}`,
        null,
        "GET",
        token
      );
      const data = await res.json();
      setCierre(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [fetchData]);

  return (
    <CierreContext.Provider
      value={{
        filter,
        setFilter,
        transportistas,
        loadingTransportistas,
        setTransportistas,
        fechaValue,
        setFechaValue,
        transportistaValue,
        setTransportistaValue,
        tiendaValue,
        setTiendaValue,
        cierre,
        setCierre,
        loading,
        setLoading,
        fetchData,
        actualizarCierre,
      }}
    >
      <div className="w-full p-content pb-16">
        <div className="flex flex-col gap-3">
          <Link to="/inventario">
            <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
              <IoMdArrowRoundBack className="text-lg" /> Volver
            </button>
          </Link>
          <div className="flex flex-col gap-4">
            <h2 className="font-black text-2xl">Cierre Diario</h2>
          </div>

          <CierreForm />

          {loading && <Spinner />}

          {cierre && !cierre?.message && <CierreDetail />}
          {cierre?.message && <CierreNotFound />}
        </div>
      </div>
    </CierreContext.Provider>
  );
}
