import React, { useCallback, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { singleFetch } from "../../utils/apiRequest";
import { DotLoader } from "react-spinners";
import { IoMdArrowRoundBack } from "react-icons/io";
import moment from "moment";
import { numberToColonesFormat } from "../../utils/formatNumbers";

export default function DetailPageClient() {
  const { telefonoCliente } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [userData, setUserData] = useState();

  const fetchSells = useCallback(async () => {
    setLoading(true);

    const filter = {
      where: {
        telefono: telefonoCliente.toString(),
      },
      order: ["id desc"],
    };

    const res = await singleFetch(
      `/ventas?filter=${JSON.stringify(filter)}`,
      null,
      "GET"
    );

    const data = await res.json();
    setData(data);
    setLoading(false);
  }, [telefonoCliente]);

  const fetchUser = useCallback(async () => {
    setLoading(true);

    const res = await singleFetch(
      `/usuarios?searchTerm=${telefonoCliente}&size=1`,
      null,
      "GET"
    );

    const data = await res.json();
    setUserData(data[0]);
    setLoading(false);
  }, [telefonoCliente]);

  useEffect(() => {
    fetchSells();
    fetchUser();
  }, [fetchSells, fetchUser]);

  return (
    <div className="w-[90%] max-w-7xl mx-auto my-0">
      {loading && (
        <div className="flex justify-center items-center">
          <DotLoader color="#000" />
        </div>
      )}

      <div className="flex flex-col gap-4 my-4">
        <Link to="/clientes">
          <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
            <IoMdArrowRoundBack className="text-lg" /> Volver
          </button>
        </Link>

        {userData && (
          <p className="text-2xl font-bold mb-4">
            Compras de {userData.nombre} {userData.apellido}
          </p>
        )}

        {data && data.length === 0 && (
          <p className="text-2xl w-full">
            Este usuario no ha realizado compras
          </p>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2  xl:grid-cols-4 gap-4">
          {data &&
            data.map((sell) => (
              <div
                className="flex gap-3 p-10 rounded-md shadow-md bg-white  flex-col border border-[#ddd]"
                key={sell.id}
              >
                <p className="font-bold text-purple">Orden # {sell.id}</p>

                <p className="text-sm text-gray-500">
                  Total con transporte: <br></br>
                  <strong>
                    {numberToColonesFormat(sell.totalConTransporte)}
                  </strong>
                </p>

                <p className="text-sm text-gray-500">
                  Fecha real pago: <br></br>
                  <strong>
                    {sell.fechaRealPago
                      ? moment(sell.fechaRealPago).format(
                          "D [de] MMMM [de] YYYY"
                        )
                      : "Pendiente"}
                  </strong>
                </p>
                <Link
                  to={`/pedidos/${sell.id}?desde=/clientes/${telefonoCliente}`}
                  className="self-end p-2 font-bold bg-purple text-white rounded-lg text-sm"
                >
                  <button>Ver detalle</button>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
