import { logoutUser } from "../slices/appSlice";
import { clearAfterPurchase } from "../slices/checkoutSlice";

const baseUrl = process.env.REACT_APP_API_URL;

const fileUploadFetch = (endpoint, file) => {
  const url = `${baseUrl}/${endpoint}`;

  const formData = new FormData();
  formData.append("file", file);
  return fetch(url, {
    method: "POST",
    body: formData,
  });
};

const singleFetch = (endpoint, data, method = "GET") => {
  const url = `${baseUrl}/${endpoint}`;

  if (method === "GET") {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};

const authFetch = async (endpoint, data, method = "GET", token, dispatch) => {
  const url = `${baseUrl}/${endpoint}`;
  let result;

  if (method === "GET") {
    result = await fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    result = fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  }
  if (result.statusText && result.statusText === "Unauthorized") {
    dispatch(logoutUser());
    dispatch(clearAfterPurchase());
  }
  return result;
};

export { singleFetch, authFetch, fileUploadFetch };
