/*import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import NotFoundForm from "../../components/NotFoundForm";
import { addToCart } from "../../slices/appSlice";
import { singleFetch } from "../../utils/apiRequest";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function AgregarPage() {
  const navigate = useNavigate();
  const { idProducto } = useParams();

  const notFoundFormRef = useRef(null);

  const [product, setProduct] = useState(null);
  const [requestedQuantity, setRequestedQuantity] = useState(null);
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [showProductNameInNotFoundForm, setShowProductNameInNotFoundForm] =
    useState(false);

  const dispatch = useDispatch();

  const query = useQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      const data = await singleFetch(`producto-detalles/${idProducto}`);
      const dataJSON = await data.json();
      setProduct(dataJSON);
      if (dataJSON) {
        try {
          const quantity = parseInt(query.get("cantidad"));
          setRequestedQuantity(quantity);
          if (dataJSON.cantidadEnBodega < quantity) {
            setName(query.get("nombre"));
            setPhone(query.get("telefono"));
            setShowProductNameInNotFoundForm(true);
            notFoundFormRef.current.scrollIntoView();
          } else {
            const petName = query.get("mascotas");
            let estimatedOutOfFoodDate = "";
            const dailyConsumption = parseInt(query.get("consumo"));
            let whatsappConfirmation = query.get("autoriza");
            whatsappConfirmation = parseInt(query.get("autoriza")) === 1;

            if (dailyConsumption) {
              const daysUntilOutOfFood = Math.floor(
                (quantity * dataJSON.pesoGramos) / dailyConsumption
              );
              const finishDate = new Date();
              finishDate.setDate(finishDate.getDate() + daysUntilOutOfFood);
              estimatedOutOfFoodDate = finishDate.toISOString();
            }
            dispatch(
              addToCart({
                product: dataJSON,
                quantity,
                petName,
                dailyConsumption,
                whatsappConfirmation,
                estimatedOutOfFoodDate,
                dontOpenCart: true,
              })
            );
            navigate(`/checkout?mascotas=${petName}`);
          }
        } catch (error) {}
      }
    };

    if (idProducto) {
      fetchProduct(idProducto);
    }
  }, [dispatch, idProducto, navigate, query]);

  return (
    <div>
      {!product ? (
        <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white">
          <Spinner />
        </div>
      ) : (
        <NotFoundForm
          fromQR
          nameParam={name}
          phoneParam={phone}
          requestedQuantity={requestedQuantity}
          ref={notFoundFormRef}
          product={showProductNameInNotFoundForm ? product : null}
        />
      )}
    </div>
  );
}

export default AgregarPage;
*/

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function AgregarPage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/pedidos");
  }, [navigate]);
  return <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white" />;
}

export default AgregarPage;
